import { InputSetting } from "../inputbox";

var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const UserUsername : InputSetting = {
    input_place_holder: "User name",
    input_surpress_header: false,
    input_name: "",
    input_type: "email",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "Errors all abound",
    input_pass: [6, emailRegex],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    
    input_container_css_setting: "mb-3",

    input_min: '',
    input_value: '',

    input_data: null,
    output_data: null
}
