import { InputSetting } from "../inputbox";

export const inputidBoard: InputSetting = {
    input_place_holder: "Board Id",
    input_surpress_header: false,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "",
    input_pass: [2, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null
}

export const inputListId: InputSetting = {
    input_place_holder: "Get cards (listId)",
    input_surpress_header: false,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "",
    input_pass: [2, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null
}

export const customCheckItem: InputSetting = {
    input_place_holder: "Something strange and different",
    input_surpress_header: true,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "off",
    input_error: "",
    input_pass: [5, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null
}

