import { Injectable } from '@angular/core';
import { ConfirmWindow, Driver, DragItem, RouteBtnOption, RouteChange } from '../classes/tool-routes';
import { TrelloService } from 'src/app/api/services/trello.service';
import { ToolRoutesService } from '../tool-routes.service';
import { TrelloCard, TrelloChecklist } from 'src/app/api/classes/trello';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolRouteOptionsService {

  constructor(
    private trelloService: TrelloService,
    private routeService: ToolRoutesService,
  ) { }

  setupButtons(btnOptions: RouteBtnOption[], from: string){
    const newRouteInfo = this.routeService.currentRouteInfo();

    newRouteInfo.view.routeBtnContextView.btnOptions = [];
    newRouteInfo.view.routeBtnContextView.btnOptions = btnOptions;

    console.log("Setting up new button for the things!!@#!#", btnOptions)

    this.routeService.nextRouteInfo(newRouteInfo, 'setupButtons')
  }

  generateEmail(dragItem: DragItem, from: string, po: string){
    // Request accociated card
    const getCardRequest = this.trelloService.getRequest('cards', dragItem.idCard);
    getCardRequest.subscribe({
      next: (data: any) =>{
        const trelloCard: TrelloCard = data;

        // Request checklist
        const checklist = trelloCard.idChecklists;

        const checklistBatchGet: string[] = []
        checklist.forEach((idChecklist: string) => { checklistBatchGet.push(this.trelloService.buildGetPath('checklists', idChecklist))});

        const checkklistBatchRequest = this.trelloService.getBatchRequest(checklistBatchGet);

        checkklistBatchRequest.subscribe({
          next: data =>{
            // Map requests and find the list in question to get the checkItems
            const checklists: TrelloChecklist[] = data.map((el:any)=> el['200']);

            console.log(checklists,dragItem.location.toLowerCase())
            let searchString = dragItem.location.toLowerCase()
            searchString = searchString.replace(/\(.*?\)/g, '').trim()

            const foundChecklist = checklists.find( store => {
              return store.name.toLowerCase().includes(searchString)});

            if(foundChecklist){
              dragItem.checkItems = foundChecklist.checkItems;
              
              dragItem.checkItems = dragItem.checkItems.sort((a,b) =>a.pos - b.pos)
              
              // Assign PO if missing
              if(!dragItem.po){ 
                const splitCardName = trelloCard.name.split('-');
                if(splitCardName && splitCardName.length > 0){
                  const cardDeliveryAddress = splitCardName[splitCardName.length-1];
                  const regexPo = /(\d+\s+\w+)/;
                  const matchPo = cardDeliveryAddress.match(regexPo);
                  if(matchPo && matchPo[0]){ 
                    dragItem.po = matchPo[0]
                  }
                }
              }

              // Got everything together so put that email up there
              const newEmail = this.routeService.buildEmail(dragItem);

              if(newEmail){
                this.routeService.nextEmail([newEmail], 'generateEmail');
                this.routeService.setView('show_email', true, null, 'btnClickOptions');
              }
            }
          },
          error: (error: any) => { console.error(error)}
        })
      },
      error: (error: any) => { console.error(error)}
    })
  }

  showConfirmWindow(type: string, data: any){
    const newRouteView = this.routeService.currentRouteInfo();

    switch(type){
      case 'archive':        

        const newConfirmWindow = new ConfirmWindow; 
        newConfirmWindow.confirmTitle= "The Archivest!";
        newConfirmWindow.confirmMessage = "Archiving something or other?";
        newConfirmWindow.confirmActions = 'archive';
        newConfirmWindow.confirmActionId = data;

        newRouteView.view.confirmWindow = newConfirmWindow;
        newRouteView.view.showConfirmWindow = true;
        newRouteView.view.showOptions = true;
        newRouteView.view.showBackground = true;

        newRouteView.view.routeBtnContextView.showBtnOptions = this.routeService.fillActiveBtnArray(null)
      break;
    }

    this.routeService.nextRouteInfo(newRouteView, 'ToolRouteOptionsService:showConfirmWindow')
    
  }

  archiveCard( idCard: string, from: string){
    const request = this.trelloService.putRequest('archive', idCard, null, null, 'archiveCard' );
    
    if(request){
      
      request.subscribe({
        next: (data: any) => {
          if(data){
            const currentRouteInfo = this.routeService.currentRouteInfo();

            const activeDrivers = currentRouteInfo.activeDrivers;
            const driverIndex = activeDrivers.findIndex(driver => driver.trelloInfo.idListDriverRoute === idCard);
            if(driverIndex > -1){ 
              console.log(currentRouteInfo.activeDrivers[driverIndex], "pre archinve")
              currentRouteInfo.activeDrivers[driverIndex].dragItems = [];
              currentRouteInfo.activeDrivers[driverIndex].routeCards = [];
              this.routeService.nextRouteInfo(currentRouteInfo,'archiveCard'); 
            };

            
            this.routeService.refreshAllBoards();            
          }
        },
        error: (error: any) => { console.error(error)}
      })
    }

  }

  moveCard(idBoard: string, idList: string, idCard: string, from: string){
    //console.log(idBoard, " ----- ",idCard," ----- ", idList, from, "<------------- 652 here.... likely the second item")

    const dataMove = {idBoard: idBoard, idList: idList};
    const putMoveCard = this.trelloService.putRequest('move_card',idCard,null,null,dataMove);

    this.routeService.generalSubscription(putMoveCard, null, 'moveCard');

  }


  changeDragItemName(newName: string, dragItem: DragItem, driver: Driver){
    // Create the post
    let postData: Observable<Object>[] = [];
    const routeChanges: RouteChange[] = [];

    // This is a change of a CheckItem
    if(dragItem.idCheckItem){
      console.log(dragItem, " <------------------", driver)
      const idCard = driver.routeCard.id;
      const idCheckItem = dragItem.idCheckItem;
      const dataCheckItem = {name: newName };
  
      postData.push(this.trelloService.putRequest('update_check_item', idCard, null, idCheckItem, dataCheckItem));

     
    } else {
      // This is a change of a checklist header
      const idList = dragItem.idChecklist;
      const dataChecklist =  {name: newName};

      postData.push(this.trelloService.putRequest('update_checklist', null, idList, null, dataChecklist));
       
      //console.log("change a checklist items");     
    }

    // Fork and complete request
    const request = forkJoin(postData);
    if(request){this.routeService.generalSubscription(request, routeChanges, 'changeDragItemName') };  

    this.routeService.setView('dismiss', true, null,'changeDragItemName'); 
  }


 
  handleError(error: any) {
    console.error('An error occured', error);
    return Promise.reject(error.message || error);
  }

  
}
