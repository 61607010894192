import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren,  } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { AdminApiService } from 'src/app/api/services/admin-api.service';
import { Driver, RouteDragDrop, DragItem, ToolRoutes, RouteBtnContextView } from './classes/tool-routes';
import { ToolRoutesService } from './tool-routes.service';
import { TrelloService } from 'src/app/api/services/trello.service';
import { TrelloCard, TrelloCheckItem, TrelloChecklist } from 'src/app/api/classes/trello';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ViewService } from 'src/app/general-services/view/view.service';
import { InputWindowService } from 'src/app/modal/input-window/input-window.service';
import { InputSetting } from 'src/app/modal/inputbox/inputbox';
import { InputSettingToolRoute } from 'src/app/modal/inputbox/inputbox.setting/tool-routes';
import { ToolRouteOptionsService } from './tool-route-options/tool-route-options.service';
import { GeneralService } from 'src/app/general-services/general/general.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tool-routes',
  templateUrl: './tool-routes.component.html',
  styleUrls: ['./tool-routes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ToolRoutesComponent implements OnInit, OnDestroy {
  @ViewChild('prototypeDriverItemList',{static: false}) public prototypeDriverList!: ElementRef;
  @ViewChild('activeDriverItemList',{static: false}) public activeDriverList!: ElementRef;

  @ViewChildren('activeDriveItems', { read: ElementRef }) activeDriveItems!: QueryList<ElementRef>;  
  
  trello_token = localStorage['trello_token'];
  
  routeInfo = new ToolRoutes;
  routesSub: Subscription;

  dragTest: boolean = false;

  showList: boolean[] = []
  boardName: string = '';

  setupDrivers: Driver[] = [];
  setupDriverSelected: boolean[] = [];
  
  showItemOrCard: boolean[][] = [[true,true,true,true,true,],[true,true,true,true,true,]];
  showCompletedOnOff: [boolean,number][] = [[true,0],[true,0],[true,0],[true,0],[true,0],];
  showExtraDriverDetails: boolean[][] = [[true,true,true,true,true,],[true,true,true,true,true,]];
  showTitgCardIndex: number = -1;

  // Clear Mask is a clear background object that will clear objects on off clicks for ADDing custom items or MOVING titg items
  showClearMask: boolean = false;

  // Drag Item Options
  showDragItemOption: boolean[][][]=[[[]],[[]],[[]],[[]]];

  // Show add input boxes
  showAddChecklist: boolean[][][]=[];
  showAddCheckItem: boolean[][][]=[];
  showAddCustomCheckItem: boolean[] = [];
  
  // Move Titg Items to Prototype or Active
  showMoveTitgItems: boolean[] = [];
  showTitgCardChecklist: boolean[][] = [];
  

  // Input Setting for App-Inputbox
  inputSettingGeneral: InputSetting = InputSettingToolRoute;

  inputValue: string = ''; // SUPER IMPORTANT: Used for inputbox mutable value
  outputValue: string = '';
 
  trackDrag: boolean = false;
  dragItemOptions: any[] = ['hello'];

  // Active Move 
  activeMove = false;

  constructor(
    private apiService: AdminApiService,
    private routeService: ToolRoutesService,
    private detectChanges: ChangeDetectorRef, 
    private trelloService: TrelloService,
    private viewService: ViewService,
    private generalService: GeneralService,
    private windowService: InputWindowService,
    private btnOptionService: ToolRouteOptionsService, 
    private sanitizer: DomSanitizer,
  ){
    this.routesSub = routeService.routeView$.subscribe(newRoute => {
      this.routeInfo = newRoute;
      this.detectChanges.markForCheck();
    });
  }

  ngOnInit(): void {
    if(this.trello_token){ 
      // Morning Protocol
      this.routeService.refreshAllBoards();
    
      // Move all today's delivery card to TiTg
      //this.routeService.movePendingToTitg();
      //this.getTrelloBoards();
    } 
    else { this.apiService.requestTrelloToken('routes')};
  }

  ngOnDestroy(): void {
    this.routesSub.unsubscribe();
  }

  // MARK - DRIVER AUTOMATION  
  moveAllDragItemFromTitg(type: string, driver: Driver, driverIndex: number, listIndex: number, itemIndex: number){
    
    // Setup items to be moved
    const currentRouteInfo = this.routeService.currentRouteInfo();

    const dragCard = currentRouteInfo.titgCardCatalog[listIndex];
    const preDragItems: DragItem[] = dragCard.dragItems;  
    

    const dragItems: DragItem[] = [];
    preDragItems.forEach(item => {if(!item.routed){dragItems.push(item)}});

    const dropData: RouteDragDrop = {
      dragCard: [dragCard], 
      dragItems: dragItems,
      nextIndex: -1, 
      previousIndex: -1,
      indexCardDriver: driverIndex,
      driver: driver,

      postItems: dragItems
    }
    
    // Initiate and Complete the moving procedure
    this.routeService.moveDragItems(dropData, 'moveTitgToDriver');

    // Dimisss Driver Selector
    this.showMoveTitgItems[listIndex] = false;
    //console.log(dragItemsToBeMoved, "<------ helo helo")

  }

  updateDragItem(type: string, driver: Driver| null, passedDragItemOrTrelloCard: DragItem|TrelloCard, driverIndex: number, indexDragItem: number, from: string): any{
    let idCard = '';
    let idCheckItem = '';
    let idChecklist = '';

    let currentDriver = new Driver;
    if(driver){ currentDriver = driver};

    const checkDragItem = passedDragItemOrTrelloCard instanceof DragItem;

    const indexes = { driverIndex: driverIndex, indexDragItem: indexDragItem };

    let dragItems: DragItem[] = [];    
    if(driverIndex > -1){dragItems = this.routeService.currentRouteInfo().activeDrivers[driverIndex].dragItems};

    // Drop Data Assignment for certain switches
    const routeDragDrop: RouteDragDrop = {
      dragCard: [], 
      dragItems: dragItems,
      previousIndex: -1,
      nextIndex: indexDragItem, 
      indexCardDriver: driverIndex,
      driver: currentDriver,

      postItems: dragItems
    }

    // Check if we have a card or DragItem
    if(checkDragItem){

      idCard = passedDragItemOrTrelloCard.idCard;
      idChecklist = passedDragItemOrTrelloCard.idChecklist;
      idCheckItem = passedDragItemOrTrelloCard.idCheckItem;
      routeDragDrop.postItems = [passedDragItemOrTrelloCard];
    }else{
      console.log(passedDragItemOrTrelloCard, "This is where the fun starts")
      idCard = passedDragItemOrTrelloCard.id;
    }

    let newData: any = {};
    switch(type){
      // MARK - UPDATE: CheckItems
      case 'update_check_item_state': 
        if(checkDragItem){ newData = {state: passedDragItemOrTrelloCard.state === 'complete' ? 'incomplete': 'complete'}};
        console.log(from, " Soemthing new and blue", passedDragItemOrTrelloCard)
        this.routeService.putTrelloItems('update_check_item', currentDriver.routeCard.id, idChecklist, idCheckItem, newData, indexes, 'updateDragItem');
      break;

      case 'update_check_item_position':
          // Get new pos
        let data = {pos: this.trelloService.setPos(routeDragDrop,'updateDragItem:update_check_item_position')};
        
        // Sub that thing
        this.routeService.putTrelloItems('update_check_item', currentDriver.routeCard.id, idChecklist, idCheckItem, data, indexes,'updateDragItem');
      break;
      
      case 'update_checklist_position':
        //console.error(currentDriver.routeCard.id, idChecklist, idCheckItem, indexes,passedDragItemOrTrelloCard," The prototyp!@#!@")

        const newPos = {pos: passedDragItemOrTrelloCard.pos}
        this.routeService.putTrelloItems('update_checklist', null, idChecklist, null, newPos, indexes,'updateDragItem');
      break;
      // MARK - UPDATE: Card
      case 'update_card_due_complete':
        newData = { dueComplete: passedDragItemOrTrelloCard.dueComplete ? false : true};
        this.routeService.putTrelloItems('update_card', idCard, null, null, newData, indexes,'updateDragItem');
      break;
      // MARK - CheckItem Update
      case 'update_checkItem_due':
        newData = { dueComplete: passedDragItemOrTrelloCard.dueComplete ? false : true};
        console.log(idCheckItem,idCard)
        this.routeService.putTrelloItems('update_check_item', idCard, null, idCheckItem, newData, indexes,'updateDragItem');

      break;
      
    }

    return true
  }

  // MARK - GET Func
  getTrelloBoards(){
    this.trelloService.getRequest('boards', null).subscribe({
      next: (data: any) => {
        if(data){
          const boards = data;
          const currentTrelloDriverInfo = this.routeService.currentRouteInfo();

          currentTrelloDriverInfo.view.trelloView.showBoards = true;
          currentTrelloDriverInfo.boards = boards;
          currentTrelloDriverInfo.view.isLoading = false;
          currentTrelloDriverInfo.view.showDrivers = true;

          this.routeService.nextRouteInfo(currentTrelloDriverInfo, 'boards')
        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  getTrelloBoard(id: string){
    this.trelloService.getRequest('board', id).subscribe({
      next: (data: any) => {
        if(data){

        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  getMasterTrelloLists(board: any, index: number){
    //console.log("REQUESTING SOME BOARDS AND THINGS ______________________________>", board)
    this.trelloService.getRequest('board_list', board.id).subscribe({
      next: (data: any) => {
        if(data){
          if(data){ 
            const currentRouteInfo = this.routeInfo;

            this.boardName = board.name;
            currentRouteInfo.lists = data;
            currentRouteInfo.view.trelloView.showLists = true;

            this.showList = [];
            this.showList[index] = true;

            this.routeService.nextRouteInfo(currentRouteInfo, "TrelloLists");
          }
        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  getCard(requestId: string,  driverIndex: number, from:string){
    //console.log(requestId, "<------------------ Requesting")
    let mapRequest = this.routeService.getRequest([requestId], from , 'cards');
    if(mapRequest){
      let request: any = null;
      if(mapRequest.length === 1){request = mapRequest[0]};
      if(request){  

        request.subscribe({
          next: (data: TrelloCard) => {
            const trelloView = this.trelloService.currentTrelloInfo();
            trelloView.active_trello_card = [];
            trelloView.active_trello_card.push(data);
            this.trelloService.nextTrelloInfo(trelloView, from);
            this.routeService.setView('show_info', true, null, 'getCard')
          },
          error: (error: any) => { console.error(error)}
        })   
      }

    }
  }

  getCards(requestId: string,  driverIndex: number, typeIndex: number, from:string){    
    let mapRequest: any
    if(typeIndex !== 0){ mapRequest= this.routeService.getRequest([requestId], from , 'list_card')}

    if(mapRequest){
      let request: any = null;

      if(mapRequest.length > 1){request = forkJoin(mapRequest)};
      if(mapRequest.length === 1){request = mapRequest[0]};

      if(request){  

        request.subscribe({
          next: (data: TrelloCard[]) => {
            const newRouteInfo = this.routeService.currentRouteInfo();
            const mapCards = this.routeService.mapActiveCardtoDrag(data, newRouteInfo.activeDrivers[driverIndex], "getCards");
            newRouteInfo.activeDrivers[driverIndex].routeCards = mapCards;
            this.routeService.nextRouteInfo(newRouteInfo, from);
          },
          error: (error: any) => { console.error(error)}
        })   
      }
    }
  }

  getPictureBoard(){

    const currentView = this.routeService.currentRouteInfo();
    const minorHeaders = currentView.view.routeViewObjects.minorHeaders;

    if(minorHeaders[5]){
      const requestId = `5f32f726dc3428568b44a7e8`;

      let mapRequest = this.routeService.getRequest([requestId], 'getPictures' , 'list_card')

      if(mapRequest){
        let request: any = null;

        if(mapRequest.length > 1){request = forkJoin(mapRequest)};
        if(mapRequest.length === 1){request = mapRequest[0]};

        if(request){  
          request.subscribe({
            next: (data: TrelloCard[]) => {
              const newRouteInfo = this.routeService.currentRouteInfo();
              newRouteInfo.utilities.pictures = data;
              newRouteInfo.view.showPictures = true;
              this.routeService.nextRouteInfo(newRouteInfo, 'getPictures');
            },
            error: (error: any) => { console.error(error)}
          })
        }
      }
    
    }
  }

  getPictures(card: TrelloCard, index: number){
    console.log(card, "<-------------------------- Pictures")

    const requestId = card.id;

    let mapRequest = this.routeService.getRequest([requestId], 'getPictures' , 'attachement')

    if(mapRequest){
      let request: any = null;

      if(mapRequest.length > 1){request = forkJoin(mapRequest)};
      if(mapRequest.length === 1){request = mapRequest[0]};

      if(request){  
        request.subscribe({
          next: (data: any) => {
            const newRouteInfo = this.routeService.currentRouteInfo();
            newRouteInfo.utilities.pictures[index].attachements = data;
            newRouteInfo.view.showBackground = true;
            this.routeService.nextRouteInfo(newRouteInfo, 'getPictures');
          },
          error: (error: any) => { console.error(error)}
        })
      }
    }
  }

  setDropLabel(dragItem: DragItem){
    console.log("Set drop laels!!!!@#", dragItem)
  }
  
  // MARK - POST Func
  createTrelloCard(listId: string ,card: TrelloCard){
    this.trelloService.buildPostPath('add_card',listId ,card).subscribe({
      next: (data: any) => {
        if(data){
        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  // MARK - Import multi POST Functions
  generateDriversRoutes(driver: Driver){ // REFACTORED 2024-03-09
    // Remove the driver that is being added
    const newRouteView = this.routeService.currentRouteInfo();
    const findAvailableDriveIndex = newRouteView.availableDrivers.findIndex(availableDriver => availableDriver === driver);
   
    if(findAvailableDriveIndex > -1){
      newRouteView.availableDrivers.splice(findAvailableDriveIndex, 1)
      this.routeService.nextRouteInfo(newRouteView, 'generateDriversRoutes');
    };

    // Prepare for diver board creation
    

    let drivers: Driver[] = [];
    drivers.push(driver)

    const postDriver = this.routeService.postDriversRouteRequest(drivers);
    
    if(postDriver){
      postDriver.subscribe({
        next: (driverCards: TrelloCard[]) => {
          // Assign Cards     
          const cardRoute = driverCards[0];
          const cardMaintenance = driverCards[1];
        
          // Link maintenance card to route card
          const nameMaintenance = 'https://trello.com/c/' + cardMaintenance.id;

          const checkItemMaintenance = this.trelloService.buildCheckItem(nameMaintenance, cardRoute.idChecklists[0], 0);
          const postAddMaitenanceCheckItem = this.trelloService.buildPostPath('add_checkItem', cardRoute.idChecklists[0], checkItemMaintenance);


          postAddMaitenanceCheckItem.subscribe({
            next: (data:any) => {
              const newDragItemMaintenance = this.routeService.mapActiveCardtoDrag([cardMaintenance],driver,'generateDriversRoutes')

              driver.routeCards.push(newDragItemMaintenance[0]);
              driver.routeCard = cardRoute
              
              // Assign New checkITem to RouteDragDrop
              const newCheckItemMaintenance = this.routeService.mapActiveDriverDragItem(data, driver, 0, 'generateDriversRoutes');
              driver.dragItems.pop()
              driver.dragItems.push(newCheckItemMaintenance);

              // Push the new Driver
              newRouteView.activeDrivers.push(driver);

              // Open the active board is not already open
              newRouteView.view.routeViewObjects.minorHeaders[4] = true;

              // Remove cloned maintenance card
              //console.log(newRouteView)

              this.routeService.nextRouteInfo(newRouteView, 'generateDriversRoutes');

            },            
            error: (error: any) => { console.error(error)}

          })            
        },
        error: (error: any) => { console.error(error)}
      })
    }
  }

  // MARK: Refresh
  initRefresh(type: string, state: number|null){
    let currentState = state
    if(state === null){ currentState = this.routeService.currentRouteInfo().view.showBoard};
    this.routeService.setView('show_board', currentState, null, 'initRefresh');
    this.routeService.refreshAllBoards();
  }

  // MARK - VIEWS and such
  keyPressedInputbox(event: any, driver: Driver|null, dragItem: DragItem|null, indexTitgOrDriver: number,indexCardOrRouteItem: number, indexItem: number, from: string){
    

    console.log(event, "------=-=-=",event,"------=-=-=", driver, "------=-=-=", dragItem, "------=-=-=", indexTitgOrDriver, "------=-=-=",indexCardOrRouteItem, "------=-=-=", indexItem, "------=-=-=", from, "------=-=-=")
    if(typeof event === 'string'){
      switch (event){
        case 'esc': 
          this.showAddChecklist =[];
          this.showAddCheckItem =[];
          this.showAddCustomCheckItem = [];
          this.routeService.setView('dimiss',null, null,'keyPressedInputbox');
          break;
        case 'enter':
          if(from.includes('checkItem')){ // Add CheckItem
            this.addCheckItem(driver, dragItem,indexTitgOrDriver, indexCardOrRouteItem, indexItem, 'keyPressedInputbox-enter:'+ from);
          } 
          if( from.includes('checklist')){ // Add Checklist
            this.addChecklist(driver, dragItem, 'keyPressedInputbox-enter:'+ from);
          }
      }
    }

    // Clear Input window
    this.setShowInput(-1,-1,-1,'dimiss');
  }

  keyPressedEnter(event: string){
    switch(event){
      case 'suppress': console.log("suppressing FIReeeee!!!"); break;
    }
  }

  showSubItems(dragItems: DragItem, from: string){
    console.log(dragItems, from)
  }

  showRoute(){
    const currentTrelloDriverInfo = this.routeService.currentRouteInfo();

    currentTrelloDriverInfo.view.trelloView.showBoards = true;
    currentTrelloDriverInfo.view.isLoading = false;
    currentTrelloDriverInfo.view.showDrivers = true;

    this.routeService.nextRouteInfo(currentTrelloDriverInfo, 'boards')
  }

  showCardInfo(idCard: string| null, from: string){
    if(idCard){
      this.getCard(idCard,  -1, from);
      
    } else { this.routeService.setView('show_dismiss', false, null,'showCardInfo')}
  }

  showIframe(url:string){
    // Wouldn't work with Trello
    this.windowService.changeModalWindowInfo('inputWindow', 'iframe', url);
    this.viewService.changeView('show_window', true, null, 'tool-routes.component:showIframe')
  }

  showContextMenu(data:DragItem| TrelloCard, boardIndex: number, driverIndex: number, itemIndex: number, domRect: DOMRect|null, type: string, from: string): boolean{
    const checkActiveOptions = this.routeInfo.view.routeBtnContextView.showBtnOptions;
    
    //console.log(from, data)
    let changeType = type;

    /* From options:
      - active_driver_item
      - active_driver_card
      - move_all_to
      - reroute
    */

    //console.log(data, " -- --1-1-1-" ,boardIndex, " --1-1-1-1-1-1--1", driverIndex, " --------------", itemIndex, "-1-1-1-1-1-1-1-", domRect,"Dofmdklmfsdfld ", type, " dkakdkakdkad", from)

    if( checkActiveOptions[boardIndex] &&
        checkActiveOptions[boardIndex][driverIndex] && 
        !checkActiveOptions[boardIndex][driverIndex][itemIndex]){

      // Set current route options to open
      const showBtnOptions = this.routeService.fillActiveBtnArray(null);
      showBtnOptions[boardIndex][driverIndex][itemIndex] = true;    

      
      // Check for move to 
      if(type === 'move_all_to'){
        const checkView = this.routeInfo.view;
        changeType = 'move_all_to_driver';
        if(checkView.showBoard === 1 ){ changeType = 'move_card_to_list'};    
      }
    
      // Setup information for actions
      const btn = this.routeService.assignRouteChange(data, driverIndex, boardIndex, itemIndex, changeType, from);
      
      // Pass the info in an object
      const passData = {showBtnOptions: showBtnOptions, btn: btn, domRect: domRect };
      //console.log("Passed data for show context menu", passData)

      this.routeService.setView('show_btn_options', null , passData, from);
    } else { 
      this.routeService.setView('dismiss', false, null, 'tool-Routes:showOptions');
    }

    return false;
  }

  setShowInput(indexBoard: number, indexChecklist: number, indexItem: number, from: string){
    // Init Default Value
    const showInput: boolean[][][] = [];

    // Clear Show Array's
    this.showAddChecklist = [];
    this.showAddCheckItem = [];
    this.showAddCustomCheckItem = [];
    this.showClearMask= false;

    if(from === 'dimisss'){return}

    // Fill the Array
    for (let x = 0; x < indexBoard +1; x++) {
      showInput.push([]);
      for (let y = 0; y < indexChecklist+1; y++) {
        showInput[x].push([])
        if(from==='customCheckItem'){showInput[x]}
        for (let z = 0; z < indexItem+1; z++) {
          showInput[x][y].push(false);
        };
      };
    };

    // Set TITG Checklist Input to True
    if(from === 'titg:checklist'){
      this.showAddChecklist = showInput;
      this.showAddChecklist[indexBoard][indexChecklist][indexItem] = true;
      this.inputSettingGeneral.input_place_holder = "Add a new checklist ";
      console.log("Showing the checklist adds", this.showAddChecklist); 

    }

    // Set TITG CheckItem Input to True
    if(from == 'titg:checkItem'){
      this.showAddCheckItem = showInput;
      this.showAddCheckItem[indexBoard][indexChecklist][indexItem] = true;
      this.inputSettingGeneral.input_place_holder = "Add a new checklist item"

    }
    
    // Set DRIVER: Add Checklist
    if(from === 'driver:checklist'){
      this.showAddChecklist[indexBoard][indexChecklist][indexItem] = true;
      this.inputSettingGeneral.input_place_holder = "Add a new checklist";
    
    }

    if(from === 'driver:checkItem'){
      this.inputSettingGeneral.input_place_holder = "Add a new checklist item"


    }
    if(from === 'driver:customCheckItem'){
      this.inputSettingGeneral.input_place_holder = "Add a new checklist item "

    }
    //this.showClearMask = true;
  }

  setShowCustomRouteInput(indexBoard: number, indexChecklist: number, indexItem: number, from: string){
    this.inputSettingGeneral.input_place_holder = "Add a new route item"

    this.showAddCustomCheckItem = [];
    for (let index = 0; index < indexBoard; index++) {this.showAddCustomCheckItem.push(false)};

    this.showClearMask = true;
    this.showAddCustomCheckItem[indexBoard] = true;
  }

  showChecklist( from: string, dragIem: DragItem, cardIndex: number, checklistIndex: number, ){
    const newRouteView = this.routeService.currentRouteInfo();

    // Set
    const newChecklistTracker = newRouteView.trackerChecklist;
    const currentChecklist = newChecklistTracker[cardIndex][checklistIndex];

    // Close the checklist (Open state)
    if(currentChecklist){
      newChecklistTracker[cardIndex][checklistIndex] = false;        
      newRouteView.trackerChecklist = newChecklistTracker;

      this.routeService.nextRouteInfo(newRouteView,"showChecklist")
    } else {
      // Request the Checklist and open it
      const idCheckist = dragIem.idChecklists[checklistIndex];
      this.routeService.getChecklist(cardIndex,checklistIndex,idCheckist,'showChecklist');
    }
  }

  showTitgSidebar(){this.routeService.setView('show_titg_sidebar',null,null,'showTitgSidebar')}
  /*
  showMoveOptions(listIndex: number, dragItem: DragItem, domRect: DOMRect){
    // Setup Route Info
    const newRouteInfo = this.routeService.currentRouteInfo();
    const checkView = newRouteInfo.view;

    //newRouteInfo.view.routeBtnContextView.showBtnOptions=true;
    newRouteInfo.view.showBackground = newRouteInfo.view.showBackground ? false: true;
    
    // Assign DOM Rect for points
    const newRouteBtnContextView = checkView.routeBtnContextView;
    newRouteBtnContextView.domRect = domRect;

    // Assign ContextMenuInfo
    const newContextMenuInfo = new RouteChange;

    newContextMenuInfo.changeType = 'move_all_to_driver';
    if(checkView.showBoard === 1 ){ newContextMenuInfo.changeType = 'move_card_to_list'};

    

    newContextMenuInfo.indexCardDriver = listIndex;
    newContextMenuInfo.passedData = dragItem;

    newRouteBtnContextView.btnContextMenuInfo = newContextMenuInfo;
    checkView.routeBtnContextView = newRouteBtnContextView

    newRouteInfo.view = checkView;

    // Set Background show and Show TITG
    //this.showMoveTitgItems[listIndex] = true;
    this.showClearMask = true; 
    this.routeService.nextRouteInfo(newRouteInfo, "showMoveOptions");
    
  }*/

  // MARK - Logic
 
  // MARK - Drag and drop Stuff
  onDrop(dropEvent: CdkDragDrop<any>, from: string): void {
    let initDel = false;

    // Previous Container Data
    const previousIndex = dropEvent.previousIndex;
    const previousContainer = dropEvent.previousContainer.data;
    const previousDropItem: DragItem = previousContainer[previousIndex];
    
    // New Container Data
    const nextIndex = dropEvent.currentIndex;
    const nextContainer = dropEvent.container.data;
    const nextDropItem = nextContainer[nextIndex];

      // Find current Driver list
    let driver: Driver = new Driver;
    const activeDrivers = this.routeService.currentRouteInfo().activeDrivers;
    const activerDriverIndex  = activeDrivers.findIndex(boards => boards.dragItems === nextContainer);
    
    if(activerDriverIndex >= 0){driver = activeDrivers[activerDriverIndex]};
    const currentBoard = previousContainer[previousIndex]['driver'];


    // Return to original container
    if (dropEvent.previousContainer === dropEvent.container) {
      //Check for TITG Drag Item sorting ( Need to block sorting out the drop system )
      //console.log(previousDropItem, "<------------ start here")
      const action = previousDropItem.action.toLowerCase();
      
      const dropCheck = action.includes('drop') || action.includes('carry') || action.includes('boom') 
      const idCheckItemCheck = previousDropItem.idCheckItem === '' && previousDropItem.driver === 'TBD';
      const titgDropCheck = dropCheck && idCheckItemCheck;

      // Continue with moving of items cause it's not the drop on the TITG Drag Item board
      if(!titgDropCheck){
        moveItemInArray(
          dropEvent.container.data,
          dropEvent.previousIndex,
          dropEvent.currentIndex
        );
        if(dropEvent.previousIndex !== dropEvent.currentIndex){
          // Drop event where a driver CheckItem pos is being swapped
          if(currentBoard){
            // Check if the Swap is for a Checklist or Checklist Item

            this.updateDragItem('update_check_item_position', driver, previousDropItem, activerDriverIndex, nextIndex, from)}
             // Drop event where a checklist is being swapped with another in the TITG list
          else{
            const posPreviousLeadingItem = nextContainer[nextIndex-1];
            //console.error(nextContainer, nextIndex, " <-----------------")
            if(previousDropItem && nextIndex !== 0){ previousDropItem.pos = posPreviousLeadingItem.pos + 1}
            else{previousDropItem.pos = 0}
           
          
            this.updateDragItem('update_checklist_position', driver, previousDropItem, activerDriverIndex, nextIndex, from)}
          } 
      }

     
    } else {        
      //console.log(currentBoard, " here is the board deter", previousContainer, previousIndex)
      
      if(currentBoard && currentBoard !== 'TBD'){initDel = true};

      // Assign Driver for emailer
      if(driver){previousContainer[previousIndex]['driver'] = driver.driverInfo.firstName}
      else{previousContainer[previousIndex]['driver'] = 'TBD'};

     
      // Move the card
      transferArrayItem(
        dropEvent.previousContainer.data,
        dropEvent.container.data,
        dropEvent.previousIndex,
        dropEvent.currentIndex
      );

      const dropData: RouteDragDrop = {
        dragCard: [], 
        dragItems: dropEvent.container.data, 
        previousIndex: previousIndex,
        nextIndex: nextIndex, 

        indexCardDriver: activerDriverIndex,
        driver: driver,

        postItems:[dropEvent.container.data[nextIndex]]
      }

      //if(dropContainerName)
      if(!initDel){ this.routeService.moveDragItems(dropData, 'moveTitgToDriver')};
      if(initDel) { this.routeService.moveDragItems(dropData, 'moveDrivertoDriver')};


    }
  
  }

  onTitgPredicate(cdkDragItem: CdkDrag<DragItem>, dragChecklist:CdkDropList<DragItem[]>): boolean{
    return false;
  }

  onDriverCardPredicate(cdkDragItem: CdkDrag<DragItem>, dragChecklist:CdkDropList<DragItem[]>): boolean{
    return false;
  }

  onDragItemPredicate(dragItem: CdkDrag<DragItem>, dragChecklist:CdkDropList<DragItem[]>){
  }

  canDropActiveItem(index: number, item: CdkDrag<any>,drop: CdkDropList<any>): boolean{
    function findHighestCompleteIndex(array: any) {
      let highestIndex = -1;
      for (let i = 0; i < array.length; i++) {if (array[i].state === 'complete') {  highestIndex = i;}}
      return highestIndex;
  }
    
    // Can't put an item higher than the maintenance card
    if(index === 0 ){ return false};

    // Find the last completed card index
    const dropCheck = drop && drop.data && drop.data.length > 0;
    if(dropCheck){
      const highestCompletedIndex = findHighestCompleteIndex(drop.data);
      if(index <= highestCompletedIndex){ console.log(highestCompletedIndex, "Lets see this");return false};
    }


    return true;
  }

  trackDropTable(){
  }

  setCheckItemString(newName: string){
    this.outputValue = newName
  }

  scrollBottom(divEl: string){
    if(divEl === 'prototypeDriver'){
      try {
        // Set the scrollTop property to the maximum scroll height
       const height = this.prototypeDriverList.nativeElement.scrollHeight;
        this.prototypeDriverList.nativeElement.scrollTop = height;

        
      } catch(err) { console.error("Error in da scrolling for ya, dis da prototyp!@!#!@#!@#")}
    }
    if(divEl === 'activeDriver'){
      try {
        // Set the scrollTop property to the maximum scroll height
        const height = this.activeDriverList.nativeElement.scrollHeight;

        this.activeDriverList.nativeElement.scrollTop = height;
      } catch(err) { console.error("Error in da scrolling for ya, dis da active!@!#!@#!@#")}
    }
  }

  // Adding and deleting items
  addChecklist( driver: Driver|null,dragItem: DragItem|null, from: string){
    if(dragItem){
      console.log(dragItem, "--------", from, this.inputValue)
    
      const idCard = dragItem.idCard;
      const newChecklist = new TrelloChecklist;
  
      let pos = 0
      if(dragItem.dragItems && dragItem.dragItems.length>0){pos = dragItem.dragItems[dragItem.dragItems.length -1].pos + 1};
  
      if(this.outputValue){
        newChecklist.name = this.outputValue;
        newChecklist.pos = pos;
    
        const postChecklist = this.trelloService.buildPostPath('checklists', idCard, newChecklist)
        if(postChecklist){
          postChecklist.subscribe({
            next: (data: any) => {     
              //dragCard.checklists.push(data)
              this.routeService.refreshAllBoards();
            },
            error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
          })
        }
      } else { console.error("Name not set!!#@#")}  
    }
  }

  addCheckItem( driver: Driver|null, dragItem: DragItem|null, indexTitgOrDriver: number, indexCardOrRouteItem: number, indexItem: number, from: string){
    console.log(" are we getting here?!?!?!", this.outputValue, dragItem)
    if(this.outputValue && (dragItem || driver)){

      console.log('Active custome drag addition');

      let idChecklist = '';
      if(from.includes('driver') && driver){idChecklist = driver.routeCard.idChecklists[0];}
      if(from.includes('titg') && dragItem){idChecklist= dragItem.idChecklist; console.log('Includes titg', from)}
      const data = { name: this.outputValue, pos: 'bottom' };
      
      this.routeService.addCheckItem(driver, dragItem, idChecklist, data, indexTitgOrDriver, indexCardOrRouteItem, indexItem, from);
    } 
    
    this.showClearMask = false;
    this.showAddCustomCheckItem = [];
 
  }

  delCheckItem(checkItem:TrelloCheckItem, indexTitgOrDriver: number, indexCardOrRouteItem: number, indexItem: number, from: string){
    const idChecklist = checkItem.idChecklist;
    const idCheckItem = checkItem.id;
    
    console.log(from, " del from!@#!@#!@", checkItem)

    this.routeService.delCheckItem(idChecklist,idCheckItem,indexTitgOrDriver, indexCardOrRouteItem, indexItem, from + ':checkItem');
  }

  // MARK - HTML Formatting
  
  // MARK - Random use Functions
  copyUrl(url: string){ this.generalService.copyText(url, "URL Copied!", 'tool-routes.components:copyUrl' )};

  sanitizeUrl(url: string): SafeUrl{
    const safeUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    return safeUrl
  }

  scrollToBottom(index: number){
    // This still doesnt work right
    const elementsArray = this.activeDriveItems.toArray();
    elementsArray[index].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    //console.log(elementsArray, " <------------ araya cdlkfjasdlkfjsldfkj", index, elementsArray[index].nativeElement);
    
  }
 
  dismissBackground(){
    //console.log("Dismissi background")
    this.routeService.setView('dismiss', true, null, 'dismissBackgroundOnContextMenu'); 
    return true;
  }

  // MARK - Shortcut Tracker
  archiveCard(idCard: string , from: string){
    const newRouteBtnContextView: RouteBtnContextView = this.routeService.generateRouteBtnContextViewFromDragItem(null, idCard,from);

    this.routeService.setRouteBtnContextView(newRouteBtnContextView, 'archiveCard')
    
    this.btnOptionService.showConfirmWindow('archive', null)};

  setItemCardSelected(typeIndex: number, driverIndex: number, driver: Driver){
    const bolCardRoute = this.showItemOrCard[typeIndex][driverIndex] ? false : true ;
    this.showItemOrCard[typeIndex][driverIndex] = bolCardRoute;
  
    if(!bolCardRoute){
      const idCheckist = driver.trelloInfo.idListDriverCards;

      this.getCards(idCheckist, driverIndex, typeIndex, 'select_route')
    }
  }

  setShowCompletedOnOff(driverIndex: number){
    const bolCardRoute = this.showCompletedOnOff[driverIndex][0] ? false : true ;
    this.showCompletedOnOff[driverIndex][0] = bolCardRoute;

    const currentDriversDragItems = this.routeInfo.activeDrivers[driverIndex].dragItems

    const completeItemCount = currentDriversDragItems.filter(el=> el.state === 'complete').length;
    this.showCompletedOnOff[driverIndex][1] = completeItemCount;
   
    console.log(driverIndex, "Make it not so!", this.showCompletedOnOff)

  }
  
  setMinorHeader(index: number){ 
    const newRouteView = this.routeService.currentRouteInfo();
    newRouteView.view.routeViewObjects.minorHeaders[index] = newRouteView.view.routeViewObjects.minorHeaders[index] ? false:true;
    this.routeService.nextRouteInfo(newRouteView, "setMinorHeader");
  }

  // MARK - Small function
  miniDriver(driverIndex: number){ this.routeService.setView('mini', null, driverIndex,'driverIndex')};
  
}
