
export class InputSetting {
    input_place_holder: string = " ";
    input_surpress_header: boolean = false;
    input_name: string = "";
    input_type: string = "";
    input_autofocus: boolean = false;
    input_autocomplete: string = "";
    input_error: string = "";
    input_pass: ([number, RegExp|string] )| null = null;
    input_pass_reqirements: any = null;
    input_disabled: boolean = false;
    input_readonly: boolean | null = false;
    input_action: string | null = null;
    input_css_setting: string = "";
    input_container_css_setting: string = ""
    input_submit: any;
    input_submit_to: any;
    input_tabindex = -1;

    input_min: string = '';
    input_value: any = null;

    input_data: InputData | null = null
    output_data: OutputData | null = null;
}


export class InputData {
    useCase: string = '';
    validCheck: boolean| null = null;
    place: any;
    placeholder: string = '';
}

export class OutputData {
    valid: boolean|null = null;
    place: [] = [];
}

export const DEFAULT_OUTPUT_DATA: OutputData = {
    valid: null,
    place: []
}

export const DEFAULT_INPUT_DATA: InputData = {
    useCase: '',
    validCheck:  null,
    place: null,
    placeholder:'',
}

export const DEFAULT_INPUT_SETTING : InputSetting = {
    input_place_holder: "",
    input_surpress_header: false,
    input_name: "",
    input_type: "",
    input_autofocus: false,
    input_autocomplete: "",
    input_error: "",
    input_pass: null,
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_container_css_setting: "mb-3",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: -1,

    input_min: '',
    input_value: null,

    input_data: DEFAULT_INPUT_DATA,
    output_data: DEFAULT_OUTPUT_DATA,
}
