<div class="trello-card-container" (click)="$event.stopPropagation()">
    <div *ngIf="trelloInfo.active_trello_card[0]" class="mt-3">
        <h1>{{trelloInfo.active_trello_card[0].name}}</h1>
    
        <!-- LABELS -->
        <div class="flex-row">
            <div *ngFor="let label of trelloInfo.active_trello_card[0].labels">
                <div 
                    style="margin: 15px; padding: 15px;" 
                    [ngStyle]="{'color': label.color}">{{label.name}}</div>
            </div>
        </div>
    
        <!-- DUE DATE-->
        <div class="flex-row">
            <div class="pl-3">{{trelloInfo.active_trello_card[0].due}}</div>
        </div>
    
        <div 
            class="trello-card-description-container"
            role="textbox" 
            aria-multiline="true" 
            contenteditable="true">
            {{trelloInfo.active_trello_card[0].desc}}
        </div >
    
        <!--div *ngIf="trelloInfo.active_trello_card[0].checklists">
            <div *ngFor="let list of trelloInfo.active_trello_card[0].checklists">
                {{list.name}}
                <div *ngIf="list.checkItems">
                    <div *ngFor="let checkItem of list.checkItems">
                        <div class="flex-row">
                            <div style="padding: 15px; margin: 15px;">{{checkItem.state}}</div>
                            <div>{{checkItem.name}}</div>
                        </div>
                    </div>
                </div>
            </div> 
        </div-->
    </div>
</div>

