import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { View } from 'src/app/general-services/view/view';
import { InputSetting } from 'src/app/modal/inputbox/inputbox';
import { UserPassword } from 'src/app/modal/inputbox/inputbox.setting/user.password';
import { UserUsername } from 'src/app/modal/inputbox/inputbox.setting/user.username';
import { AccountService } from '../services/accounts.service';
import { ViewService } from 'src/app/general-services/view/view.service';
import { DefaultAppConfig } from 'src/app/app.config';
import { DEFAULT_ACCOUNT_INFO, DEFAULT_USER_INFO } from '../classes/account-settings';


@Component({
  selector: 'app-account-landing',

  templateUrl: './account-landing.component.html',
  styleUrls: ['./account-landing.component.css']
})

export class AccountLandingComponent implements OnInit, OnDestroy {

  login_info = {
    user_email:'',
    user_password: ''
  }

  errResponse = "There was something that went terrible wrong...oh oh"
  showErrResponse = false;

  user_username: InputSetting = UserUsername;
  user_password: InputSetting = UserPassword;

  pass_check = [false,false];
  btn_disabled = true;

  view = new View;
  viewSub: Subscription;

  constructor(
    private accountSevice: AccountService,
    private viewService: ViewService,
    private detectChanges: ChangeDetectorRef
  ){
    this.viewSub = viewService.view$.subscribe(view => {
      this.view = view;
      this.detectChanges.markForCheck();
    });
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.viewSub.unsubscribe();
  }
  
  submit(value: string|null, index: number){
 
    if(!value){value=''}

    if(index === 0){this.login_info.user_email = value}
    if(index === 1){this.login_info.user_password = value}

    if(value){ this.pass_check[index]= true}else{this.pass_check[index]=false}
    
    let pass_check = false;
    if(this.login_info.user_email !== '' && this.login_info.user_password !== ''){ pass_check = true}

    if(!pass_check){this.btn_disabled = true} else { this.btn_disabled = false}
  }

  login(){
    // MARK - Login
    const request = this.accountSevice.login(this.login_info, 'login');
    //console.log("loadddign this bitch --------------->>>><<<<")
    if(!DefaultAppConfig.DEFAULT_TESTING){
      if(request){
        this.viewService.changeView('loading', true, null, 'account-landing.component:login');
  
        request.subscribe({
          next: (data: any) => {
            //console.log(data, "<---- Login information")
            if(data.status !== 'Error'){ 
              const account_data = this.accountSevice.populateAccount(data);
              this.accountSevice.newAccountInfo(account_data);
              this.viewService.changeView('tools', true, null, 'account-landing.component:login');
            }else{
              this.showErrResponse = true;
              this.errResponse = data.message;
            }
          },
          error: (error: any) => { this.showErrResponse = true; this.errResponse = "Something went wrong during the process... blame the process";console.error(error)}
        })
      }
    } else {
      const current_user = DEFAULT_ACCOUNT_INFO;
      this.accountSevice.newAccountInfo(current_user);
      this.viewService.changeView('tools', true, null, 'account-landing.component:login')
    }
  
  }  
}
