<h1 class="mt-4"> ROUTeAraTOR!</h1>
<div class="tool-container"
    [ngClass]="{'push-left': showTitgContainer,
                'push-right': !showTitgContainer}">
    <!-- Clear components -->
    <div *ngIf="showClearMask">
        <div class="route-checkitem-background" 
            (click)="showAddCustomCheckItem = [[],[]]; 
                    showMoveTitgItems = []; 
                    showClearMask = false;
                    inputValue = '';">
        </div>
    </div>
    <!-- Show Route page-->
    <div *ngIf="routeInfo.view.showRoutePage">
    <!-- MARK - ROUTE VIEW: Show Boards-->
        <div *ngIf="routeInfo.view.trelloView.showBoards">
            <div *ngIf="true">
                <h2 class="route-minor-header" (click)="setMinorHeader(0);getTrelloBoards()">
                    Available Trello Boards
                    <span >
                        <img 
                        class="route-minor-img" 
                        src="assets\images\chevron_right.svg" 
                        alt=""
                        [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[0] ? 'rotate-cw-90':'rotate-origin']">
                    </span>
                </h2>
                <div *ngIf="routeInfo.view.routeViewObjects.minorHeaders[0]" class="trello-board-container">
                    <div *ngFor="let board of routeInfo.boards; let index = index" class="trello-boards">
                        <ng-container
                            [ngTemplateOutlet]="trelloBoard"
                            [ngTemplateOutletContext]="{board: board, index: index}">
                        </ng-container>
                    </div>
                </div>    
            </div>
        </div>

<!-- MARK - ROUTE PLANNER-->
        <!--h2 class="route-minor-header" (click)="setMinorHeader(1)">
            Here are your fellows
            <span >
                <img 
                class="route-minor-img" 
                src="assets\images\chevron_right.svg" 
                alt=""
                [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[1] ? 'rotate-cw-90':'rotate-origin']">
            </span>
        </h2>

        <button class="btn btn-primary" (click)="setupRoutingSystem()">Driver Boards</button>
    
        <div *ngIf="routeInfo.view.routeViewObjects.minorHeaders[1]" class="trello-board-container">
            <div *ngFor="let board of routeInfo.boards; let index = index" class="trello-boards">
                <ng-container
                    [ngTemplateOutlet]="trelloBoard"
                    [ngTemplateOutletContext]="{board: board, index: index}">
                </ng-container>
            </div>
        </div>
        -->
        <h2 class="mt-5" >Driver Setup Program</h2>
        <div class="flex-row" cdkDropListGroup>

<!-- MARK - CURRENT DRIVERs -->
            <div style="overflow: hidden; flex: 1;">            
                <h2 class="route-minor-header" (click)="setMinorHeader(2)">
                    Available Drivers
                    <span >
                        <img 
                        class="route-minor-img" 
                        src="assets\images\chevron_right.svg" 
                        alt=""
                        [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[2] ? 'rotate-cw-90':'rotate-origin']">
                    </span>
                </h2>    
                <div class="flex-row">
                    <div class="route-drivers-container">
                        <div class="trello-board-container" *ngIf="routeInfo.view.routeViewObjects.minorHeaders[2]">
                            <div class="route-information-text">Click to on drivers to begin the routing process!!!</div>
                            <div class="flex-row" style="overflow: auto;">
                                <div *ngFor="let driver of routeInfo.availableDrivers; let index = index" 
                                    class="route-driver-container"
                                    (click)="generateDriversRoutes(driver)"
                                    [ngClass]="{'route-driver-container-selected': setupDriverSelected[index]}">                                  
                                    <ng-container
                                        [ngTemplateOutlet]="selectableDriverBoard"
                                        [ngTemplateOutletContext]="{driver: driver, index: index}">
                                    </ng-container>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
<!-- MARK - Active Boards -->
                <div *ngIf="routeInfo.activeDrivers && routeInfo.activeDrivers.length && routeInfo.activeDrivers.length > 0" class="flex-row">
                    <div class="route-drivers-container">
                        <h3 class="route-minor-header" (click)="setMinorHeader(4)">
                            Active Boards
                            <span >
                                <img 
                                class="route-minor-img" 
                                src="assets\images\chevron_right.svg" 
                                alt=""
                                [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[4] ? 'rotate-cw-90':'rotate-origin']">
                            </span>
                        </h3>
                        <div  *ngIf="routeInfo.view.routeViewObjects.minorHeaders[4]">
                            <div class="route-information-text mt-3">
                                You can drag and drop route into the setup drivers
                         
                                <button
                                    (click)="initRefresh('titg', null)" 
                                    class="float-right btn btn-primary"
                                    style="z-index: 10;position: relative; margin-left: 7.5px;">
                                    Refresh
                                </button>
                                <button 
                                    class="float-right btn btn-primary" 
                                    style="z-index: 10;position: relative;" 
                                    (click)="miniDriver(-1)">Mini all
                                </button>
                            </div>
                            <div class="route-driver-board">
                                <div 
                                    class="container" 
                                    style="
                                        overflow-x: auto;
                                        overflow-y: hidden;
                                        box-shadow: #6d8091  1px 1px 6px 0px ;"> 
                                    <div *ngFor="let driver of routeInfo.activeDrivers; let driverIndex = index; first as isFirst" 
                                    class="route-driver-card-container"
                                    [ngClass]="{'route-driver-container-mini': routeInfo.trackerMini[driverIndex]}"
                                    [ngStyle]="{'z-index': showTitgContainer ? '': 2}"
                                    tabindex="0"
                                    
                                    #activeDriveItems

                                    (mouseenter)="showDragItemOption[1]=[]"
                                    ><!--(keydown.c)="archiveCard(true,driver.routeCard.id, 'archiveRoutec')"-->
                                    
<!-- MARK: Options - Options Btn's -->
                                    <ng-container
                                        [ngTemplateOutlet]="optionsRouteDeliveries"
                                        [ngTemplateOutletContext]="{driver: driver, typeIndex: 1, driverIndex: driverIndex}">
                                    </ng-container>
<!-- MARK: Route || Card container  -->                                        
                                    
<!-- MARK - ACTIVE - ITEMS -->                                            
                                    <div class="route-item-list-container"  
                                        *ngIf="showItemOrCard[1][driverIndex] && !routeInfo.trackerMini[driverIndex]" 
                                        #activeDragItem = "cdkDropList"

                                        id="activeDragItem"
                                        cdkDropList

                                        [cdkDropListData]="driver.dragItems"
                                        [cdkDropListSortPredicate]="canDropActiveItem"
                                        

                                        (mouseenter)="showDragItemOption[0]=[];showDragItemOption[0][driverIndex]=[]"
                                        (cdkDropListDropped)="onDrop($event, 'moveDrivertoDriver')">

                                        <div 
                                            *ngFor="let dragItem of driver.dragItems; let itemIndex = index;first as isFirst" 
                                            class="route-checkitem"
                                            #activeItem
                                            cdkDrag

                                            (contextmenu)="showContextMenu(dragItem,0,driverIndex ,itemIndex, activeItem.getBoundingClientRect(), 'active_driver_item', 'active')"
                                            
                                            tabindex="0"

                                            [cdkDragData]="dragItem"
                                            [cdkDragDisabled]="dragItem.state === 'complete'|| dragItem.action.includes('MAINTENANCE') || dragItem.disableDrag || routeInfo.view.routeBtnContextView.showBtnOptions[0][driverIndex][itemIndex] " 
                                            [ngClass]="{'hide-element-no-pad-no-marg': (!showCompletedOnOff[driverIndex][0] && dragItem.state === 'complete')}">     
                                            
                                            <div class="container px-0">
                                                <div class="flex-row" style="align-items: center;">
                                                    <!--div class="btn-group-vertical">
                                                        <input 
                                                            type="checkbox" 
                                                            [checked]="dragItem.state === 'complete'"
                                                            (change)="updateDragItem('update_check_item_state', driver, dragItem, driverIndex, itemIndex)">
                                                    </div-->
                                                
                                                    <!-- D Type Information (General Drag Template)-->
                                                    <div class="route-driver-drag-item-container"
                                                        [ngClass]="{'text-lt':dragItem.state === 'complete'}">
                                                        <div *ngIf="false">
                                                            <ng-container 
                                                                [ngTemplateOutlet]="dragTemplate"
                                                                [ngTemplateOutletContext]="{dragItem:dragItem, dragPrototype: false}">
                                                            </ng-container>
                                                        </div>
                                                        <ng-container 
                                                            [ngTemplateOutlet]="templateDriverDragItem"
                                                            [ngTemplateOutletContext]="{dragItem:dragItem, driver: driver, dragPrototype: false, driverIndex: driverIndex, itemIndex: itemIndex}">
                                                        </ng-container>
                                                        
                                                    
                                                    </div> 
                                                    
                                                    <!-- MARK - SHOW Options-->
                                                    
                                                </div>
                                                                                        
                                            </div>

                                          
                                        
                                        </div>
                                        <div *ngIf="showCompletedOnOff[driverIndex][1] === driver.dragItems.length" 
                                        style="padding: 15px;
                                            word-wrap: break-word;
                                            white-space: normal;
                                            text-align: center;">
                                            <h2>Driver Route Complete, time for updates or the pool!!</h2>
                                        </div>
                                                                
                                    <ng-container 
                                        [ngTemplateOutlet]="customChecklistItem"
                                        [ngTemplateOutletContext]="{driverIndex: driverIndex, type: 'activeDriver',boardIndex: 1 }">
                                    </ng-container>
                                    </div>
<!-- MARK - ACTIVE - CARDS -->
                                    <div 
                                        *ngIf="!showItemOrCard[1][driverIndex]"
                                        #activeDragCard = "cdkDropList"

                                        id="activeDragCard"
                                        cdkDropList

                                        [cdkDropListData]="driver.routeCards"
                                        [cdkDropListConnectedTo]="['activeDragCard']"
                                        [cdkDropListEnterPredicate]="onDriverCardPredicate"

                                        (mouseenter)="showDragItemOption[0]=[];showDragItemOption[0][driverIndex]=[]"
                                        
                                        (cdkDropListDropped)="onDrop($event, 'moveDrivertoDriver')">
                                        <div 
                                            *ngFor="let card of driver.routeCards; let itemIndex = index;first as isFirst" 
                                            #activeCardItem
                                            class="route-checkitem"
                                            cdkDrag
                                            tabindex="0"

                                            (contextmenu)="showContextMenu(card,1,driverIndex, itemIndex, activeCardItem.getBoundingClientRect(), 'active_driver_card', 'active')"                                             

                                            [cdkDragData]="card"
                                            [cdkDragDisabled]="card.disableDrag" >     
                                        
                                            <!--(keydown.c)="archiveCard(true,card.id, 'activeDriverBoard')"-->
                                            
                                            <input class="m-3" 
                                                type="checkbox" 
                                                [checked]="card.dueComplete"
                                                (change)="updateDragItem('update_card_due_complete', driver, card, driverIndex, itemIndex)">   
                                                {{card.name}}
                                            
                                            <!-- MARK - SHOW Options-->
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
<!-- MARK - Utilities (Pictures , etc...)-->
                <div class="flex-row">
                    <div class="route-drivers-container">
                        <h3 class="route-minor-header" (click)="setMinorHeader(5);getPictureBoard()">
                            Pictures
                            <span >
                                <img 
                                class="route-minor-img" 
                                src="assets\images\chevron_right.svg" 
                                alt=""
                                [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[5] ? 'rotate-cw-90':'rotate-origin']">
                            </span>
                        </h3>
                        <div  *ngIf="routeInfo.view.routeViewObjects.minorHeaders[5]" class="flex-row" style="overflow: auto;flex-wrap: wrap;">
                            <div 
                                *ngFor="let pictureCard of routeInfo.utilities.pictures; let i = index" 
                                style="position: relative;">
                                <div class="route-checkitem route-picture-card-container" 
                                    style="cursor: pointer !important; min-height: 160px;"
                                    (click)="getPictures(pictureCard, i)">   
                                    {{pictureCard.name}}                                  
                                </div>


                                <div *ngIf="routeInfo.view.showPictures">
                       
                                    <div *ngFor="let attachment of pictureCard.attachements" class="route-picture-container">
                                        <div class="container">
                                            <div>{{attachment.date}}</div> 
                                            <a 
                                                href="{{attachment.url}}"
                                                style="background-image: url('https://trello.com/1/cards/65b156bdfe1c1caa84885a5a/attachments/65b3b05ff40f0ad836eeb11d/previews/65b3b060f40f0ad836eeb381/download/image.jpg');"></a>           
                                        </div>
                                    </div>                 
                                </div>  
                            </div>

                            <div *ngIf="routeInfo.view.showPictures">
                                <div style="min-height: 200px;">

                                    <h3>Nothing to show for it</h3> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
<!-- MARK - Available Deliveries --> 
<!-- TITG-->
            <animation-right-left
                class="route-titg-animation-container"

                [isRightLeft]="showTitgContainer" 
                [id]="'toolbar'">
                <div class="tool-route-mini-titg-container"
                    [ngStyle]="{'z-index': (showTitgContainer ? '': -1)}">
                    <button
                        class="btn btn-naked tool-route-mini-titg-button" 
                        (click)="showTitgContainer = showTitgContainer ? false:true"
                        [ngStyle]="{'z-index': (showTitgContainer ? '': 100)}"> 
                        <img src="assets/images/chevron_right.svg" 
                            alt=""
                            style="height: 22px;"
                            [ngClass]="{'rotate-cw-180': !showTitgContainer,
                                        'rotate-origin': showTitgContainer}">
                    </button>

                </div>
                <div class="route-titg-container" >                

                    <h3>TitG
                        <button (click)="initRefresh('titg', 0)" class="btn btn-naked" style="margin: 10px;" [ngClass]="{'route-selected-option': routeInfo.view.showBoard === 0}">Live</button>
                        ||
                        <button (click)="initRefresh('titg', 1)" class="btn btn-naked" style="margin: 10px;" [ngClass]="{'route-selected-option': routeInfo.view.showBoard === 1}">Team Board</button>
                        ||
                        <button (click)="initRefresh('titg', 2)" class="btn btn-naked" style="margin: 10px;" [ngClass]="{'route-selected-option': routeInfo.view.showBoard === 2}">Testing</button>
                    </h3>
                    <div class="route-information-text">You can drag and drop the following checklists items into the drivers routes!!</div>
    
                    <div class="route-titg-sub-container" >
                        <div class="route-titg-cards-container"  >
                            <div  
                                class="route-titg-cards-list"
                                id="titgDragCard" 
                                (mouseenter)="showDragItemOption[2]=[]">
                                <div 
                                    *ngFor="let dragCard of routeInfo.titgCardCatalog; let listIndex = index" 
                                    class="route-titg-checklists-items"
                                    #idDragCard>  
    <!-- MOVE button's-->
                                    <div *ngIf="
                                        routeInfo.view.routeBtnContextView.showBtnOptions&&
                                        routeInfo.view.routeBtnContextView.showBtnOptions[3] &&
                                        routeInfo.view.routeBtnContextView.showBtnOptions[3][listIndex] &&
                                        routeInfo.view.routeBtnContextView.showBtnOptions[3][listIndex][0]"
                                        style="position: relative; display: block;">
                                        
                                    </div>
    <!-- Button group for card actions -->
                                    <div 
                                        class="btn-group-vertical float-right" 
                                        style="
                                            position: absolute;

                                            top: -15px;
                                            right: -10px;

                                            padding: 5px;
                                            
                                            border-radius: 7px;
                                            border:  1px #c9c9c9 solid;  
                                            background-color: white;" >
    <!-- Move ALL DRAGITEMS to active or prototype driver (Not where the move happens) -->
                                       
                                       <button 
                                            #btnMove
                                            class="route-options-btn btn-naked" 
                                            style="background-image: url(assets/images/send_to_route.svg);"
                                            (click)="showContextMenu(dragCard, 3, listIndex, 0, btnMove.getBoundingClientRect(), 'move_all_to', 'titg')"
                                            [disabled]="dragCard.dragItems.length < 1">                                      
                                        </button>
    <!-- Share link -->
                                        <button 
                                            #btnMove
                                            class="route-options-btn btn-naked" 
                                            style="background-image: url(assets/images/envelope.svg);"
                                            (click)="copyUrl(dragCard.url)">                                      
                                        </button>
    <!-- Card Information -->
                                       <button 
                                            class="route-options-btn btn-naked"
                                            style="background-image: url(assets/images/info_blue.svg);"
                                            (click)="showCardInfo(dragCard.idCard, 'tool-routes:titgCards')">
                                        </button>
                                   </div>
    <!-- Titg Card header-->
                                    <h3 class="mb-1">{{dragCard.name}} ( {{dragCard.dragItems.length}} ) </h3>
                                    <div class="route-titg-date-container">
                                        <input                                                             
                                        type="checkbox" 
                                        [checked]="dragCard.dueComplete"
                                        (change)="updateDragItem('update_card_due_complete', null, dragCard, -1, listIndex)">
    
                                        <div>{{dragCard.due}}</div>
                                    </div>      
    
                                    <app-trello-labels [labels]="dragCard.labels"></app-trello-labels>
    
    
                                    <h4 class="my-2" style="border-bottom: 1px solid black; padding: 10px 0px;" hidden>
                                        Checklist items
                                    </h4>
    
                                    <div class="flex-row">
                                        <div       
                                            #titgDragItem="cdkDropList"   
    
                                            cdkDropList
                                            id="titgDragItem"
                                            class="col-12 float-left"
                                        
                                            [cdkDropListData]="dragCard.dragItems"
                                            [cdkDropListEnterPredicate]="onTitgPredicate"
                                            
                                            (cdkDropListDropped)="onDrop($event, 'moveTitgtoDriver')"
                                            (mouseenter)="showDragItemOption[2][listIndex]=[]">
    <!-- TITG Drag items-->
                                            <div 
                                                class="route-checkitem" 
                                                *ngFor="let dragItem of dragCard.dragItems; let itemIndex = index;first as isFirst; "
                                                
                                                #titgItem
                                                cdkDrag
    
                                                (contextmenu)="showContextMenu(dragItem, 2, listIndex, itemIndex, titgItem.getBoundingClientRect(), 'titg_drag_item', 'titg')"                                             
    
                                                [cdkDragData]="dragItem"
                                                [cdkDragDisabled]="
                                                    dragItem.disableDrag || 
                                                    (   routeInfo.view.routeBtnContextView.showBtnOptions && 
                                                        routeInfo.view.routeBtnContextView.showBtnOptions[2] &&  
                                                        routeInfo.view.routeBtnContextView.showBtnOptions[2][listIndex] && 
                                                        routeInfo.view.routeBtnContextView.showBtnOptions[2][listIndex][itemIndex])" 
                                                [ngClass]="{'text-lt':dragItem.routed}"
                                                [style.margin-top.px]="isFirst? '35px' : '15px'">
                                                    <div class="flex-row" style="align-items: center;">
                                                        <div *ngIf="dragItem.name !== 'Missing drop LAbel!'" style="width: 370px;">{{dragItem.name}}</div>
                                                        
                                                        <!-- Missing drop label -->
                                                        <div *ngIf="dragItem.name === 'Missing drop LAbel!'" class="w-100">
                                                            {{dragItem.name}} 
                                                            <span class="float-right">
                                                                <button 
                                                                (click)="$event.stopPropagation();setDropLabel(dragItem)"
                                                                class="btn btn-danger">                                                                
                                                                    Set Drop Label
                                                                </button>
                                                            </span>
                                                        </div>
    
                                                        <div *ngIf="(dragItem.action.toLowerCase() === 'drop' || dragItem.action.toLowerCase() === 'carry') && !dragItem.idChecklist">
                                                            <span class="float-right">
                                                                <button 
                                                                [disabled]="true"
                                                                (click)="$event.stopPropagation();setDropLabel(dragItem)"
                                                                class="btn btn-naked">                                                                
                                                                    Change drop 
                                                                </button>
                                                            </span>
                                                        </div>
                                                      
                                                        <div 
                                                            *ngIf="dragItem.idChecklists && dragItem.idChecklists[itemIndex]"
                                                            style="position: relative;z-index: 1;cursor: pointer;"  
                                                            (click)="$event.stopPropagation(); showChecklist('titgCards',dragItem,listIndex,itemIndex);">
                                                            <img 
                                                            class="route-minor-img" 
                                                            src="assets\images\chevron_right_grey.svg" 
                                                            alt=""
                                                            style="float: none;"
                                                            [ngClass]="[routeInfo.trackerChecklist[listIndex][itemIndex] ? 'rotate-cw-90':'rotate-origin']">
                                                            
                                                        </div>
                                                    </div>
                                               
                                                    <div *ngIf="false">
                                                        <ng-container 
                                                            [ngTemplateOutlet]="dragTemplate"
                                                            [ngTemplateOutletContext]="{dragItem:dragItem, dragPrototype: false}">
                                                        </ng-container>
                                                        
                                                    </div>
                                                    
                                                    <div *ngIf="
                                                        routeInfo.trackerChecklist[listIndex][itemIndex] &&
                                                        dragItem.idCard !== '666'" 
                                                        class="" style="border-top: 1px solid #696969; margin: 10px">
                                                        <ng-container 
                                                            [ngTemplateOutlet]="trelloChecklist"
                                                            [ngTemplateOutletContext]="{dragItem:dragItem}">
                                                        </ng-container>
                                                    </div>
    
                                                    <div *ngIf="dragItem.idCard === '666'"> 
                                                        MISSING THE CHECKLIST SUCKA!!!! Add it now
                                                    </div>
    
                                                    
                                                <!-- MARK - SHOW Options-->
                                                <!--div 
                                                    *ngIf="!trackDrag && showDragItemOption[2] && showDragItemOption[2][listIndex] && showDragItemOption[2][listIndex][itemIndex]">
                                                    <ng-container 
                                                        [ngTemplateOutlet]="checkItemsOptions"
                                                        [ngTemplateOutletContext]="{buttons: setupCheckItemButtons(dragItem, listIndex, 'titg'), dragItem: dragItem, typeIndex: 2, listIndex: listIndex, itemIndex: itemIndex}">
                                                    </ng-container>
                                                </div-->
                                            </div>  
                                              
                                        
                                            <h3 *ngIf="dragCard&&dragCard.dragItems&& dragCard.dragItems.length === 0">
                                                ALL DONE! (You've routed the stuff!)
                                            </h3>
                                        </div>
                                    </div>
                                </div>
    
                                <h3 *ngIf="routeInfo.titgCardCatalog.length < 1" class="route-titg-card-message" >
                                    Nothing to be routed
                                </h3>                          
                            </div>
                            
                          
                        </div>
                    </div>
                </div>
            </animation-right-left> 
        </div>

    </div>

<!-- MARK - MODAL COMMIT SCREEN-->
    <div *ngIf="routeInfo.view.showBackground"  >
        <div 
            class="route-background"              
            (click)="dismissBackground();"
  
            (contextMenu)="dismissBackground()">
            <!--div *ngIf="routeInfo.view.show"></div-->
            <app-tool-route-options *ngIf="routeInfo.view.showOptions"></app-tool-route-options>
            <app-trello-card *ngIf="routeInfo.view.trelloView.showCard"></app-trello-card>
            <app-tool-route-email *ngIf="routeInfo.view.showEmailOrder"></app-tool-route-email>
        
        </div>
      
    </div>

    <!-- Loading Container -->
    <div *ngIf="routeInfo.view.isLoading">
        <div class="" style="background: transparent;width: 100%; height: 100%;">
            Loading container
        </div>
    </div>

    <div *ngIf="!routeInfo.view.showRoutePage">Failure to Launch, may the Trello be with ya...</div>

</div>

<!--h2>Trello information repository</h2-->
<!--button class="btn btn-primary flex-row m-3" style="min-width: 300px" (click)="getTrelloBoards()">Get me some boards iD'S</button-->



<!-- MARK - TEMPLATES -->

<!-- TEMPLATE: Boards -->
<ng-template
    #trelloBoard
    let-board="board"
    let-index="index">
    <div (click)="getMasterTrelloLists(board, index)">  
        <div class="trello-row-board">
            <div class="col-6">
                <div class="trello-item-labels">{{board.name}}</div>
            </div>
            <div class="col-6 text-right">
                <div class="trello-item-labels">id - {{board.id}}</div>
            </div>
        </div> 
            
        <div 
            *ngIf="routeInfo.view.trelloView.showLists && showList[index]" 
            class="trello-row-list">
            <div 
                *ngFor="let list of routeInfo.lists" 
                style="margin-right: 10px"
                >
                <ng-container
                    [ngTemplateOutlet]="trelloList"
                    [ngTemplateOutletContext]="{list: list}">
                </ng-container>
            </div>
            
        </div>
    </div>
    <!-- ROUTE VIEW: Show Lists-->

</ng-template>

<!-- TEMPLATE: Lists -->
<ng-template
    #trelloList
    let-list="list">
    <div class="trello-list-headers">{{list.name}}<br>{{list.id}}</div>
</ng-template>

<!-- TEMPLATE: Cards -->
<ng-template
    #trelloCard
    let-card="card">
    <div class="btn btn-naked">{{card.name}}</div>
</ng-template>

<!-- TEMPLATE: Driver -->
<ng-template
    #selectableDriverBoard
    let-driver="driver"
    let-index="index">
    <div *ngIf="driver">
        <div class="route-driver-img-container">
            <img
                class="route-driver-img" 
                src="{{driver.driverInfo.driverImg}}" 
                alt="/assets/images/roomerator_icon_grey.svg">
        </div>
        <div class="col-5 float-left">
            <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">First name</div>
            <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Middle name</div>
            <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Last name</div>
            <br>
            <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Start time</div>
            <br>
            <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Vehicle Name</div>
            <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Vehicle Id</div>
        </div>
        <div class="col-7 float-right">
            <div>{{driver.driverInfo.firstName}}</div>
            <div class="route-driver-label">{{driver.driverInfo.middleName}}</div>
            <div>{{driver.driverInfo.lastName}}</div>
            <br>
            <div>{{driver.driverInfo.startTime}}</div>
            <br>
            <div>{{driver.driverInfo.vehicleName}}</div>
            <div>{{driver.driverInfo.vehicleId}}</div>
        </div>
    </div>
    
</ng-template>

<!-- TEMPLATE: Active Driver BTN Console -->
<ng-template 
    #optionsRouteDeliveries
    let-driver="driver"
    let-typeIndex = "typeIndex"
    let-driverIndex="driverIndex">
    <div *ngIf="!routeInfo.trackerMini[driverIndex]" class="flex-row" style="align-items: center;justify-content: center;">    
        <div class="route-driver-card-container-header">{{driver.routeCard.name}} ({{driver.dragItems.length}})</div>
    </div>
    <div *ngIf="routeInfo.trackerMini[driverIndex]">{{driver.driverInfo.initial}}</div>
    <!-- Bottom Button Menu (Archive & Update/Refresh)-->                                    
    <div class="route-btn-container" [ngStyle]="{'top': routeInfo.trackerMini[driverIndex] ? '44px': '0px' }">
        <div class="m-2" 
        style="display: inline-flex;position: relative; vertical-align: middle;"
        [ngClass]="{'list-group': routeInfo.trackerMini[driverIndex]}">
            <button class="btn btn-naked route-options-btn"
                [ngStyle]="{
                    'background-image': showCompletedOnOff[driverIndex] ? 'url(assets/images/show_hidden.svg)' :'url(assets/images/show_show.svg)' }"
                (click)="setShowCompletedOnOff(driverIndex)">
                <div *ngIf="!showCompletedOnOff[driverIndex][0] && routeInfo.activeDrivers[driverIndex].dragItems.length"
                    class="btn-navbar-count-container route-driver-hidden-count">
                    {{showCompletedOnOff[driverIndex][1]}}
                </div>
            </button>
            <button
                class="btn btn-naked route-options-btn"
                [ngStyle]="{
                    'background-image': showItemOrCard[1][driverIndex] ? 'url(assets/images/route_plan_2.svg)' :'url(assets/images/cards.svg)' }"
                (click)="setItemCardSelected(1, driverIndex, driver)">
                <div *ngIf="showItemOrCard[1][driverIndex]"
                    class="btn-navbar-count-container route-driver-hidden-count">
                    {{routeInfo.activeDrivers[driverIndex].dragItems.length}}
                </div>
                <div *ngIf="!showItemOrCard[1][driverIndex]"
                    class="btn-navbar-count-container route-driver-hidden-count">
                    {{routeInfo.activeDrivers[driverIndex].routeCards.length}}
                </div>
            </button>
            <button
                class="btn btn-naked route-options-btn"
                style="background-image: url(assets/images/archive_red.svg);"
                (click)="archiveCard(driver.routeCard.id, 'archiveRoute')">
            </button>
            <button 
                class="btn btn-naked route-options-btn"
                [ngStyle]="{'background-image': routeInfo.trackerMini ? 'url(assets/images/minimize.svg)': 'url(assets/images/maximize.svg)'}"
                (click)="miniDriver(driverIndex)">
            </button>

        </div>
    </div>
</ng-template>

<!-- TEMPLATE: Drag Item -->
<ng-template 
    #dragTemplate
    let-dragItem="dragItem"
    let-dragPrototype="dragPrototype">
    <div *ngIf="!dragTest">
        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.po" hidden><span class="mr-2"></span>{{dragItem.name}}</div>

        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.action" hidden><span class="mr-2">Action:</span>{{dragItem?.action?.toLocaleUpperCase()}}</div>
        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.name && !dragPrototype"><span class="mr-2"></span> {{dragItem.name}}</div>
       
        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.location" hidden><span class="mr-2">Location:</span>{{dragItem.location}}</div>      
        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.po" hidden><span class="mr-2">Display Name:</span>{{dragItem.po}}</div>
    </div> 

    
    <!--div 
        *ngIf="dragItem.idChecklists && dragItem.idChecklists[itemIndex]"
        style="position: relative;z-index: 1;cursor: pointer;"  
        (click)="$event.stopPropagation(); showChecklist('titgCards',dragItem,listIndex,itemIndex);">
        <img 
        class="route-minor-img" 
        src="assets\images\chevron_right_grey.svg" 
        alt=""
        style="float: none;"
        [ngClass]="[routeInfo.trackerChecklist[listIndex][itemIndex] ? 'rotate-cw-90':'rotate-origin']">
    </div-->      

    <!-- Prototype Drag items -->
    <div *ngIf="dragPrototype">
        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.displayName" ><span class="mr-2"></span> {{dragItem.name}}</div>
    </div>

    <div *ngIf="dragTest">
        <div class="flex-row" class="general-drag-template">{{dragItem.action.toLocaleUpperCase()}} &#64; {{dragItem.location}}</div>
    </div>
</ng-template>

<!-- TEMPLATE: Prototype New Drag Item-->
<ng-template 
    #templateDriverDragItem
    let-dragItem="dragItem"
    let-driver="driver"
    let-dragPrototype="dragPrototype"
    let-driverIndex="driverIndex"
    let-itemIndex="itemIndex">
 
    <div class="route-driver-drag-item-major-header">
        <h4 *ngIf="dragItem.url">{{dragItem.displayName}}</h4>
    </div>
    <div class="flex-row">
        <div class="btn-group-vertical">
            <input 
                type="checkbox" 
                [checked]="dragItem.state === 'complete'"
                (change)="updateDragItem('update_check_item_state', driver, dragItem, driverIndex, itemIndex)">
        </div>
        <div class="route-driver-drag-item-minor-header-container">
                <div *ngIf="!dragItem.url" style="font-size: medium;">{{dragItem.displayName}}</div>
                <div *ngIf="dragItem.url && !dragItem.action" style="font-size: medium;">{{dragItem.displayName}}</div>

                <!-- Extra details -->
                <div class="flex-column">
                    <div *ngIf="dragItem.po" class="route-driver-drag-item-minor-header" style="text-transform: capitalize;">
                        <img class="route-driver-drag-item-img" style="height: 12px !important;" src="assets\images\x_button_black.svg" alt="">
                        {{dragItem.po}}
                    </div>
    
                    <div *ngIf="dragItem.url" class="route-driver-drag-item-minor-header" style="text-transform: capitalize;">
                        <img class="route-driver-drag-item-img" style="height: 12px !important;" src="assets\images\x_button_black.svg" alt="">
                        {{dragItem.action}}
                    </div>
    
                    <div *ngIf="dragItem.url" class="route-driver-drag-item-minor-header" style="text-transform: capitalize;">
                        <img class="route-driver-drag-item-img" src="assets\images\location.svg" alt="">
                        {{dragItem.location}}
                    </div>
    
                    <div *ngIf="dragItem.url" class="route-driver-drag-item-minor-header" style="white-space: nowrap;">
                        <img class="route-driver-drag-item-img" 
                            style="height: 12px !important;" 
                            src="assets\images\x_button_black.svg" 
                            alt="">
                        <a  href="{{dragItem.shortUrl}}"
                            style=" width: 258px;
                                    overflow: hidden;">{{dragItem.shortUrl}}</a> 
                        <div>
                            <button class=" btn btn-naked route-copy-btn-container" (click)="copyUrl(dragItem.url)"> 
                                <img class="route-driver-drag-item-img" 
                                style="height: 12px !important;" 
                                src="assets\images\x_button_black.svg" 
                                alt=""> copy
                            </button>
                        </div>
                    </div>
    
                    <h4 *ngIf="dragItem.team">Team members: {{dragItem.teamMembers}}</h4>
                </div>
              
            </div>
       </div>
  </ng-template>

<!-- TEMPLATE: Custom route item -->
<ng-template 
    #customChecklistItem
    let-driverIndex="driverIndex"
    let-type="type"
    let-boardIndex="boardIndex">
    <div 
        class="route-custom-item-container" style="cursor: pointer !important; z-index: 1000;"
        [ngStyle]="{'background': (showAddCustomCheckItem[boardIndex] && !showAddCustomCheckItem[boardIndex][driverIndex] ? 'transpartent !important':'') }"
        
        (click)="showCustomChecklistItem(boardIndex, driverIndex, customCheckItem)">
        <div 
            *ngIf="showAddCustomCheckItem[boardIndex] && showAddCustomCheckItem[boardIndex][driverIndex]"
            class="route-custom-checkitem-btn">
            <app-inputbox 
                (enterPressed)="addCustomItem(driverIndex, type)"  
                (validData)="customCheckItemString = $event"
                [inputSetting]="customCheckItem"
                [inputValue]="inputValue"
            ></app-inputbox>
            <div class="flex-row">
                <button class="btn btn-primary" (click)="$event.stopPropagation();addCustomItem(driverIndex, type)">Add</button>
                <button class="btn btn-danger ml-2" (click)="$event.stopPropagation();showAddCustomCheckItem = [[],[]];showClearMask = false;inputValue = '';">X</button>
            </div>        
        </div>
        
        <div class="route-custom-checkitem-btn" [ngStyle]="{'z-index': routeInfo.view.showBackground ? '': '1010'}"
            *ngIf="showAddCustomCheckItem[boardIndex] && !showAddCustomCheckItem[boardIndex][driverIndex] && showItemOrCard[boardIndex] && showItemOrCard && showItemOrCard[boardIndex][driverIndex]">
            + Add a custom checklists item
        </div>
    </div>
</ng-template>

<!-- TEMPLATE: Card Checklist-->
<ng-template
    #trelloChecklist
    let-dragItem="dragItem">
        <div *ngFor="let checkItem of dragItem.checkItems; let checkItemIndex = index;"
            class="container pt-2" >
            <div class="flex-row pb-2" style="align-items: center;">
                {{checkItem.name}}
            </div>
        </div>
   
</ng-template> 

