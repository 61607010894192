
<!-- Confirm Window -->
<div *ngIf="routeInfo.view.showConfirmWindow">
    <div class="options-window-container" (click)="$event.stopPropagation()">
        <div class="options-window-holder" (click)="$event.stopPropagation()">
            <h2>{{routeInfo.view.confirmWindow.confirmTitle}}</h2>
            <h4>{{routeInfo.view.confirmWindow.confirmMessage}}</h4>
            <div class="flex-row" style="justify-content: space-around;">
                <button class="btn btn-naked text-warning" style="padding: 15px;" (click)="dismissBackgroundOnContextMenu()">Cancel</button>
                <button class="btn btn-naked text-danger" style="padding: 15px;" (click)="$event.stopPropagation();btnClickOptions('archive',true, -1)">{{routeInfo.view.confirmWindow.confirmBtnTitle}}</button>
            </div>
        </div>
       
    </div>
</div>

<!-- Buttons and stuff -->
<div [ngClass]="{'invisible':routeInfo.view.showConfirmWindow}" #containerInfo
    (click)="dismissBackgroundOnContextMenu()">
    <div 
        #containerButtons
        class="flex-row"
        [ngClass]="{'flex-row-reverse': !rowDirection}">

        <!-- Button image: Just about everything else -->
        <div #containerImport class="import">
            <ng-container class="h-100 w-100" *ngIf="changeType !== 'move_all_to_driver' && changeType !== 'move_card_to_list'"
                [ngTemplateOutlet]="dragTemplate"
                [ngTemplateOutletContext]="{dragItem: dragItem}">
            </ng-container>
        </div>
        
        <!-- The buttons-->
        <div *ngIf="btnMain.length > 0"
            (click)="$event.stopPropagation();dismissBackgroundOnContextMenu()"
            class="options-btn-container"
            [ngStyle]="{'align-items': (from === 'titg' ? 'self-end':'')}"
            #optionsTop>
            <div 
                *ngFor="let mainBtn of btnMain; let iMain = index" 
                class="options-btn-container"
                [ngStyle]="{'flex-direction': (from === 'titg' ? 'row-reverse':'row'),
                            'max-width': mainBtn.disabled ? '120px': ''}">
                <ng-container 
                    [ngTemplateOutlet]="templateBtn"
                    [ngTemplateOutletContext]="{dragItem: dragItem, btn: mainBtn, index:iMain}">
                </ng-container>
                
                <div 
                *ngIf="showSubBtns[iMain]"
                    class="options-btn-container"
                    
                    [ngStyle]="{'align-items': (from === 'titg' ? 'flex-end':'flex-start')}"
                    (click)="$event.stopPropagation()">
                    
                    <!-- The sub Buttons -->
                
                    <div 
                        *ngFor="let subBtn of mainBtn.btnSub; let iSub = index"   
                        (click)="btnClickOptions(subBtn, null, iSub)"
                        style="padding-left: 20px;">
                        <ng-container 
                            [ngTemplateOutlet]="templateBtn"
                            [ngTemplateOutletContext]="{dragItem: dragItem, btn: subBtn, index: iSub}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- TEMPLATE: Driver container-->

<!-- TEMPLATE: Drag and Drop Card information -->
<ng-template 
    #dragTemplate
    let-dragItem="dragItem"
    let-dragPrototype="dragPrototype">
    <div class="h-100 w-100" *ngIf="!dragTest && dragItem && dragItem.name">
        <!--div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.name" ><span class="mr-2"></span> </div-->
        <textarea 
            #textareaDragItem
            class="textarea-edit-section route-checkitem"
            (click)="$event.stopPropagation();"
            (keydown.enter)="modifyName(textareaDragItem.value, dragItem)">{{dragItem.name}}</textarea>
    </div> 
    <!-- Prototype Drag items -->
    <div *ngIf="dragPrototype">
        <div class="flex-row general-drag-template" *ngIf="dragItem && dragItem.displayName" ><span class="mr-2"></span> {{dragItem.name}}</div>
    </div>

    <div *ngIf="dragTest">
        <div class="flex-row" class="general-drag-template">{{dragItem.action.toLocaleUpperCase()}} &#64; {{dragItem.location}}</div>
    </div>
 
</ng-template>

<!-- TEMPLATE: Buttons-->
<ng-template 
    #templateBtn    
    let-dragItem = "dragItem"
    let-btn="btn"
    let-index="index">
    <button 
        class="option-btn-container btn-naked" 
        [disabled]="btn.disabled"
        
        (click)="$event.stopPropagation();btnClickOptions(btn, dragItem, index)">
        <div 
            class="btn {{btn.class}} "
            [ngStyle]="{'background-image' : 'url(' + btn.image + ')'}"
            >            
        </div>    
        <div style="margin: 0 7px;">{{btn.name}}</div>
        <div *ngIf="btn.completed" class="option-img-ordered">
            <img style="height: 17px;" src="assets/images/checkmark_green.svg" alt="">
        </div>

    </button>
  
</ng-template>

