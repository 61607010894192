import { Observable } from "rxjs";
import { CARD_DAILY_MAINTENANCE, TrelloBoard, TrelloCard, TrelloCheckItem, TrelloChecklist, TrelloLabel } from "src/app/api/classes/trello";

export class ToolRoutes {
    prototypeDrivers: Driver[] = [];
    availableDrivers: Driver[] = [];
    activeDrivers: Driver[] = [];
    view: RouteView = new RouteView;
    boards: TrelloBoard[] = [];
    cards: TrelloCard[] = []
    lists: TrelloChecklist[] = [];
    titgCardCatalog: DragItem[] = [];

    activeCard: TrelloCard[] = [];

    trackerMini: boolean[] = [];
    trackerChecklist: boolean[][]=[];

    utilities: RouteUtilities = new RouteUtilities;

}

export class Driver {
    driverInfo: DriverInformation = new DriverInformation;
    trelloInfo: DriverTrelloInformation = new DriverTrelloInformation;
    
    routeCard: TrelloCard = new TrelloCard;
     
    routeCards: DragItem[] = [];
    routeChecklist: TrelloCheckItem[] = [];

    dragItems: DragItem[] = [];
}

export class DriverInformation {
    firstName: string = 'TBD';
    lastName: string = '';
    initial: string = '';
    middleName: string = '';

    driverImg: string = '';

    startTime: string = '';
    startLocation: string = '';

    vehicleName: string = '';
    vehicleId: string = '';

    idDriver: string = '';
}

export class DriverTrelloInformation{    
    idBoardDriver: string = '';
    idListDriverRoute: string = '';
    idListDriverHolding: string = '';
    idListDriverCards: string = '';

}

export class RouteView {
    showBoard: number = 0; // 0: TITG, 1: Team, 2: Testing 
    isLoading: boolean = false;

    showRoutePage: boolean = false;
    showDrivers: boolean = false;
    showBackground: boolean = false;
    showEmailOrder: boolean = false;
    showIframe: boolean = false;
    showConfirmWindow: boolean = false;
    showOptions: boolean = false;
    showPictures: boolean = false;
    confirmWindow: ConfirmWindow = new ConfirmWindow;

    routeViewObjects: RouteViewObjects = new RouteViewObjects;
    trelloView: TrelloView = new TrelloView;

    infoToolOptions: any[] = [];
    routeBtnContextView: RouteBtnContextView = new RouteBtnContextView;
}

export class RouteViewObjects{
    minorHeaders: boolean[] = [false,false,false,false,];
}

export class TrelloView {
    showBoard: boolean = false;
    showBoards: boolean = false;
    showLists: boolean = false;
    showCards: boolean = false;
    showCard: boolean = false;
}

export class ConfirmWindow {
    confirmTitle: string = '';
    confirmMessage: string = '';
    confirmActions: string = '';
    confirmActionId: string = '';
    confirmBtnTitle: string = 'Archive';
}

// Major DOMO
export class DragItem{
    name: string = '';
    displayName: string = '';

    clientName: string = '';
    companyName: string = '';

    checklistItem: string = '';
    due: Date = new Date;
    dueComplete: boolean = false;
    url: string = '';
    shortUrl: string = '';

    driver: string|null = null;
    po: string|null = null;
   
    location: any|null = null;

    disableDrag: boolean = false;

    idBoard: string = '';
    idChecklist: string = '';
    idChecklistDriverBoard: string = '';
    idCard: string = '';
    idCheckItem: string = '';
    idDriver: string = '';
    idRouteChecklist: string = '';
    idChecklists: string[] = [];
    idList: string = '';
    idLabels: string[] = [];

    labels: TrelloLabel[] = [];
    checklists: TrelloChecklist[] = [];

    dragItems: DragItem[] = [];
    checkItems: TrelloCheckItem[] = [];

    state: string|null = 'incomplete';
    pos: number = -1;
    
    dragType: string = ''; // types: activeDriverCard

    associatedTrelloCard: TrelloCard[] = [];

    linked: boolean = false;
    action: string = '';
    ordered: boolean|null = null;
    routed: boolean|null = false;
    team: boolean|null = false;
    teamMembers: Driver[] = [];
    linkable: boolean = true;
}

// Used in drag and drop functions 
export class RouteDragDrop{
    indexCardDriver: number = -1;
    driver: Driver = new Driver;
    postItems: DragItem[] = [];

    dragCard: DragItem[] = [];
    dragItems: DragItem[] =[];
    
    nextIndex: number = -1; 
    previousIndex: number = -1;
}

export class RouteChange{
    changeType: string = ''; // Types are create, del, move, modify
    indexCardDriver: number = -1;
    indexDragItem: number = -1;
    indexCheckItem: number = -1;
    from: string = ''; // Flex direction
    dragItem: DragItem = new DragItem;
    trelloCard: TrelloCard = new TrelloCard;
}

export class RouteUtilities{
    pictures: TrelloCard[] =[];
}

export class RouteVendor{
    name: string = '';
    type: string = '';
    id: number = -1; // this will need to be changed to a primary keep that should be made using alphanum
    tel: string = '';
    location: string = '';
    emailAddress: string [] = [];
    emailType: number = -1;
}

export class RouteEmailTemplate{
    title: string = '';
    body: string = '';
    type: string = '';
}

export class RouteBtnContextView{
    btnOptions: RouteBtnOption[] = [];

    domRect: DOMRect|null = null;
    btnContextMenuInfo: RouteChange|null = null;

    showBtnOptions: boolean[][][] = [ [[]], [[]], [[]], [[]]];
    showSubOption: string = '';
}

export class RouteBtnOption{
    name: string = '';

    type: string = '';
    clickAction: string = '';
    disabled: boolean = false;

    completed: boolean = false;
    class: string = '';
    image: string = '';

    btnSub: RouteBtnOption[] = [];
}

export class EmailOrder {
    email_from: string = '';
    email_to: string = '';
    email_cc: string = '';
    email_subject: string = '';
    email_body: string = '';
}

// MARK - Defaults
export const DEFAULT_ROUTE_VIEW: RouteView = {
    showBoard: 0,

    isLoading: false,
    showRoutePage: false,

    showDrivers: false,
    showBackground: false,
    showIframe: false,
    showEmailOrder: false,
    showConfirmWindow: false,
    showOptions: false,
    showPictures: false,


    confirmWindow: new ConfirmWindow,
    routeViewObjects: new RouteViewObjects,
    routeBtnContextView: new RouteBtnContextView,
    trelloView: new TrelloView,

    infoToolOptions: [],
}

export const DEFAULT_ROUTE_CHANGES: RouteChange[] = [
    {    //0
        changeType: 'add_checkItem', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //1   
        changeType: 'add_card', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //2   
        changeType: 'checklists', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //3   
        changeType: 'del_checkItem', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //4   
        changeType: 'update_checklist', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //5   
        changeType: 'update_check_item', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //6   
        changeType: 'update_card', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //7   
        changeType: 'move_card', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },{ //8 
        changeType: 'archive', 
        indexCardDriver: -1,
        indexDragItem: -1,
        indexCheckItem: -1,
        from: '',
        dragItem: new DragItem,
        trelloCard: new TrelloCard,
    },
]

export const DEFAULT_DRIVER_CHECKLIST: TrelloChecklist = {
    name: 'Checklist',
    checkItems: [],
    idBoard: '',    
    id: '',
    idCard: '', 
    pos: -1,
    state: 'incomplete'
}

export const DEFAULT_ROUTE_UTILITIES: RouteUtilities = {
    pictures: []
}

export const DEFAULT_TOOL_ROUTE: ToolRoutes = {
    prototypeDrivers: [],
    availableDrivers: [],
    activeDrivers: [],
    view: DEFAULT_ROUTE_VIEW,
    boards: [],
    cards: [],
    lists: [],

    titgCardCatalog: [],
    activeCard: [],

    trackerChecklist: [],
    trackerMini: [],

    utilities: DEFAULT_ROUTE_UTILITIES ,
}

export const DEFAULT_EMAIL_ORDER: EmailOrder[] = [{
    email_from: "jeffrey_garand@hotmail.com",
    email_cc: "orders@roomerator.com",
    email_body: "This is my body, my sexy body, I do a little turn on the chat walk, yes the chat walk, yes... THE CAT WALK!",
    email_to: 'jeff@roomerator.com',
    email_subject: "New order(Roomerator)",
}]

// MARK - Drag n Drop Items
export const ROUTE_MAINTENANCE_CHECKLIST_ITEM: DragItem = {
    name: 'Van Maintenance - Daily',
    displayName: 'Van Maintenance - Daily',
    companyName: 'Roomerator',
    clientName: '',
    
    checklistItem: '',
    checkItems: [],
    due: new Date,
    dueComplete: false,

    url: '',
    shortUrl: '',
    //card: CARD_DAILY_MAINTENANCE,
    location: null,
    disableDrag:  true,

    po: null,
    driver: null,
    
    idCard:CARD_DAILY_MAINTENANCE.id,
    idChecklists:[],
    idChecklist: '',    
    idChecklistDriverBoard:  '',
    idCheckItem: '',
    idDriver: '',
    idRouteChecklist: '',
    idBoard: '',
    idLabels: [],
    idList: '',

    state: 'incomplete',
    labels: [],
    
    checklists: [],

    dragItems: [],
    associatedTrelloCard: [],

    dragType: 'activeDriverDragItem',
    pos: 1,
    linked: false,
    action: 'maintenance',
    ordered: null, 
    routed: false,
    team: false,
    teamMembers: [],
    linkable: false,
}

// Drivers should be populated via the server
export const CURRENT_DRIVERS: Driver[] = [
// NATHAN MCQUILKIN
    {
        driverInfo: {
            idDriver: '61',
            firstName: 'Nathan',
            middleName: '',
            lastName: 'McQuilkin',
            initial: 'NM',
            
            driverImg: '',
            
            startTime: '06:00',

            vehicleName: 'Jennifer',        
            vehicleId: '61',

           

            startLocation: '[9]-1704 Meyerside Rd, Mississauga, ON L5T 1A3',
        },

        // Trello Stuff
        trelloInfo:{
            idListDriverHolding:  "603c17db3effeb2917426f3e",
            idBoardDriver: "5cc8df57680cc53dca0d0076",
            idListDriverRoute: '5d8366b6186a8b6c10d51236',
            idListDriverCards: '5cd4cd0c24faa306030c027e',
        },
     
        routeCard: new TrelloCard,
        
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]

    },
// NAHUM ADAMU
    {
        driverInfo: {
            idDriver: '9',
            firstName: 'Nahum',
            middleName: 'Balit',
            lastName: 'Adamu',
            initial: 'NBA',
            
            driverImg: '',

            startTime: "08:00",
            startLocation: 'Rona Parkdale',
            
            vehicleId: '9',
            vehicleName: 'Mia',
        },
       
        trelloInfo: {
            idListDriverHolding: "6089362155eaed327fbaccbc",
            idBoardDriver: "603c269b38338d689df1c5a6",
            idListDriverRoute: '603c27b7fc4c224ac5c01120',
            idListDriverCards: '603c27bd6a924c4d475ca8d9',
        },
        // Trello Stuff
      
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    
    },
// BLAKE BROWN
    {
        driverInfo: {
            idDriver: '7',
            firstName: 'Blake',
            middleName: 'Jesse',
            lastName: 'Brown',
            initial: 'BJB',
            
            driverImg: '',
            startTime: "07:00",
            startLocation: 'HD Davidson',
    
            vehicleId: '7',
            vehicleName: 'Bella',
        },

        // Trello Stuff
        trelloInfo:{       
            idListDriverHolding: "5f0fb45cd0f45f7725c3ae32",
            idBoardDriver: "5e67e1769aa65b895e4c3be9",
            idListDriverRoute: '5e67e19b19e76227a64dec96',
            idListDriverCards: '5e67e1982fc1642dc02511ea',
        },

    
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    },/*
// NEw Driver
    {
        driverInfo: {
            firstName: '',
            middleName: 'NOT EARNED',
            lastName: '',
            initial: '',
            
            driverImg: '',

            startTime: "06:00",
            startLocation: '1704 Meyerside Dr. Unit #9',
            
            vehicleId: 'TBD',
            vehicleName: 'TBD',
        },
       
        trelloInfo: {
            idListDriverHolding: "65cf3dae4a3234776a9f0c76",
            idBoardDriver: "5d8a4aab49d1ff0f0293354b",
            idListDriverRoute: '5d8a96251085c36941708c0d',
            idListDriverCards: '5d8a95e05a705e8f25899c2a',
        },
        // Trello Stuff
      
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    
    },*/
// ADD ON SLAVES
    {
        driverInfo: {
            idDriver: '0',
            firstName: 'Jeffrey',
            middleName: 'Robert',
            lastName: 'Garand',
            initial: 'JRG',
            
            driverImg: '',
            startTime: '05:00',

            startLocation: '1529 Clearwater Dr, Mississauga ON',
            
            vehicleId: '-1',
            vehicleName: 'Da Cloud',

        },
        trelloInfo:{
            idListDriverHolding:  "603c17db3effeb2917426f3e",
            idBoardDriver: "5ceb1acc784b294a81c1e647",
            idListDriverRoute: '5d8a95a497297c8449bdf953',
            idListDriverCards: '5d7f70afa4501f3820934201',    
        },
       
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    },{
        driverInfo: {
            idDriver: '1',
            firstName: 'Christopher',
            middleName: 'Andrew',
            lastName: 'Garand',
            initial: 'CAG',
            
            driverImg: '',
                
            startTime: '07:30',

            startLocation: '1529 Clearwater Dr, Mississauga ON',
            vehicleId: '-1',
            vehicleName: 'Da Cloud',

        },

        trelloInfo:{      
            idListDriverHolding:  "603c17db3effeb2917426f3e",
            idBoardDriver: "5d8a4aab49d1ff0f0293354b",
            idListDriverRoute: '5d8a96251085c36941708c0d',
            idListDriverCards: '5d8a95e05a705e8f25899c2a',    
        },
  
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    }
]

export const TESTING_DRIVER: Driver[] = [
    {
        driverInfo: {
            idDriver: 'team',
            firstName: 'Team',
            middleName: 'Work',
            lastName: 'Something',
            initial: 'TWS',
            
            driverImg: '',
            startTime: '05:00',

            startLocation: '1529 Clearwater Dr, Mississauga ON',
            vehicleId: '-1',
            vehicleName: '',
    
        },
        trelloInfo: {
            idListDriverHolding:  "5fdc8648a910c384984cb1c6",
            idBoardDriver: "5d9d218d17b87227bfe9980a",
            idListDriverRoute: '5d9d21938f451d773ed3b1ef',
            idListDriverCards: '5d9d21a16bd1798e365b31cd',    
        },
       
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    },{
        driverInfo: {
            idDriver: '0',
            firstName: 'Jeffrey',
            middleName: 'Robert',
            lastName: 'Garand',
            initial: 'JRG',
            
            driverImg: '',
            
            startTime: '05:00',

            startLocation: '1529 Clearwater Dr, Mississauga ON',
            vehicleId: '-1',
            vehicleName: 'None',
        },

        trelloInfo: {
            idListDriverHolding:  "603c17db3effeb2917426f3e",
            idBoardDriver: "5ceb1acc784b294a81c1e647",
            idListDriverRoute: '5d8a95a497297c8449bdf953',
            idListDriverCards: '5d7f70afa4501f3820934201',    
        },
       
        routeCard: new TrelloCard,
        routeCards: [],
        routeChecklist: [],
        dragItems: [ROUTE_MAINTENANCE_CHECKLIST_ITEM]
    },
]

// MARK - Vendors
export const DEFAULT_VENDORS: RouteVendor[] = [
// RONA
   {
        name: 'Rona RW',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '2311 Royal Windsor Dr, Oakville, ON L6J 7X7',
        emailAddress: ["mike.goldsworthy@rona.ca","blair.palloway@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona+ Burlington',
        id: -1,
        type: 'rona',
        tel: '9053313795',
        location: '3270 Harrison Cres, Burlington, ON L7M 0W4',
        emailAddress: ["tyler.davies@rona.ca","Joshua.Trueman@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Erin Mills',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '2933-A Eglinton Ave W, Mississauga, ON L5M 6J3',
        emailAddress: ["Rose.Podzimek2@rona.ca","jeff.lamont@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Speers',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '399 Speers Rd, Oakville, ON L6K 3T2',
        emailAddress: ["kevin.tighe@rona.ca","jolene.quinn@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Dundas',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '650 Dundas St E, Mississauga, ON L5A 3Y6',
        emailAddress: ["donna.blackwood@rona.ca","Elicia.Ling@rona.ca","rick.duffy@rona.ca","Andrew.Drobenko@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Stockyards',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '110 W Toronto St, Toronto, ON M6N 5B7',
        emailAddress: ["Peysadr@rona.ca","prodesk513@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona MG',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '1170 Martin Grove Rd, Etobicoke, ON M9W 4X1',
        emailAddress: ["dhavina.narayan@rona.ca","Craig.Donaldson@rona.ca","winston.brown@rona.ca","pro.55090@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Sheppard',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '258 Sheppard Ave E, North York, ON M2N 3B1',
        emailAddress: ["John.Soares@rona.ca","pro.55030@rona.ca","Sam.kanter@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Parkdale',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '633 Parkdale Ave N, Hamilton, ON L8H 5Z1',
        emailAddress: ["anthony.brands@rona.ca","mike.bell@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Markham',
        id: -1,
        type: 'rona',
        tel: '9058250465',
        location: '8651 McCowan Rd, Markham, ON L3P 4H1',
        emailAddress: ["Nakeera.baanilall2@rona.ca","Jing.hu@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona Golden Mile',
        id: -1,
        type: 'rona',
        tel: '4166893570',
        location: '800 Warden Ave, Scarborough, Ontario, M1L 4T7',
        emailAddress: ["lking@ron.ca","stephen.bain@rona.ca"],
        emailType: 1 
    },{
        name: 'Rona York',
        id: -1,
        type: 'rona',
        tel: '4167802770',
        location: '1300 Castlefield Ave, Toronto, ON M6B 4B3',
        emailAddress: ["2099071@rona.ca"],
        emailType: 1 
    },
// WATSON
    {
        name: 'Watson Burlington',
        id: -1,
        type: 'watson',
        tel: '9058250465',
        location: '3191 Mainway, Burlington, ON L7M 1A6',
        emailAddress: ["burlingtonorders@watsonbuildingsupplies.com"],
        emailType: 1 
    },{
        name: 'Watson Vaughan',
        id: -1,
        type: 'watson',
        tel: '9058250465',
        location: '50 Royal Group Crescent #2, Vaughan, ON L4H 1X9',
        emailAddress: ["vaughanshipping@watsonbuildingsupplies.com"],
        emailType: 1 
    },
// EMCO
    {
        name: 'Emco Burlington',
        id: -1,
        type: 'emco',
        tel: '9058250465',
        location: '1150 Corporate Dr, Burlington, ON L7L 5R6',
        emailAddress: ["Rkelly@emcoltd.com"],
        emailType: 1 
    },
// TRANS CANADA
    {
        name: 'Trans Canada Wood Prodcuts Ltd',
        id: -1,
        type: 'trans',
        tel: '9058250465',
        location: '7735 Simcoe County Rd 27, Barrie, ON L9J 0A4',
        emailAddress: ["naomi@transcanadagroup.on.ca"],
        emailType: 1 
    },
// HD
    {
        name: 'HD Bristol Circle',
        id: -1,
        type: 'hd',
        tel: '9058250465',
        location: '2555 Bristol Cir, Oakville, ON L6H 5W9',
        emailAddress: ["prodesk_7003@homedepot.com"],
        emailType: 2 
    },{
        name: 'HD Davidson',
        id: -1,
        type: 'hd',
        tel: '9058250465',
        location: '3050 Davidson Ct, Burlington, ON L7M 4M9',
        emailAddress: ["Prodesk_7021@homedepot.com"],
        emailType: 2 
    },{
        name: 'HD NQ',
        id: -1,
        type: 'hd',
        tel: '9058250465',
        location: '193 N Queen St, Etobicoke, ON M9C 4Y1',
        emailAddress: ["prodesk_7011@homedepot.com"],
        emailType: 2 
    },{
        name: 'HD Burloak',
        id: -1,
        type: 'hd',
        tel: '9058250465',
        location: '3300 S Service Rd W, Oakville, ON L6L 0B1',
        emailAddress: ["prodesk_7115@homedepot.com"],
        emailType: 2 
    },{
        name: 'HD Stockyards',
        id: -1,
        type: 'hd',
        tel: '9058250465',
        location: '2121 St Clair Ave W, Toronto, ON M6N 5A8',
        emailAddress: ["prodesk_7013@homedepot.com"],
        emailType: 2 
    },{
        name: 'HD Rexdale',
        id: -1,
        type: 'hd',
        tel: '9058250465',
        location: '1983 Kipling Ave, Etobicoke, ON M9W 4J4',
        emailAddress: ["prodesk_7114@homedepot.com"],
        emailType: 2 
    },
]

// MARK - Buttons
export const DEFAULT_ROUTE_OPTIONS_BUTTON: RouteBtnOption[] = [
    {
        name: 'Email',
        type: 'email_order',
        clickAction: 'email_order',
        class: 'option-btn',
        disabled: false,
        completed: false,
        image: "assets/images/envelope_resize.svg",
        btnSub: [],
        
    }, {
        name: 'Info',
        type: 'info',
        clickAction: 'info',
        class: 'option-btn',
        disabled: false,
        completed: false,
        image: "assets/images/info_blue.svg",
        btnSub: [],
        
    },{
        name: 'Modify',
        type: 'modify',
        clickAction: 'modify',
        class: 'option-btn',
        disabled: false,
        completed: false,
        image: "assets/images/modify.svg",
        btnSub: [],
        
    },{
        name: 'Move all',
        type: 'move_card',
        clickAction: 'move_card',
        class: 'option-btn',
        disabled: false,
        completed: false,
        image: 'assets/images/truck_left_arrow_black.svg',
        btnSub: [],
    },{
        name: 'Archive',
        type: 'archive',
        clickAction: 'archive',
        class: 'option-btn',
        disabled: false,
        completed: false,
        image: "assets/images/logout_red.svg",
        btnSub: [],        
    },{
        name: 'Delete',
        type: 'delete',
        clickAction: 'delete',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "assets/images/x_button_red.svg",
        btnSub: [],
        
    },{
        name: 'Reroute',
        type: 'routed',
        clickAction: 'remove_routed',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "assets/images/truck_left_arrow_red.svg",
        btnSub: [],
        
    },{
        name: 'Team',
        type: 'team',
        clickAction: 'show_teams',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "assets/images/truck_left arrow_black.svg",
        btnSub: [],
        
    },
    // SUB OPTIONS
    {
        name: 'Titg',
        type: 'move_titg',
        clickAction: 'move_titg',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "",
        btnSub: [],
        
    },{
        name: 'Team\'s',
        type: 'move_team',
        clickAction: 'move_team',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "",
        btnSub: [],
        
    },{
        name: 'Photo\s',
        type: 'move_photo',
        clickAction: 'move_photo',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "",
        btnSub: [],
        
    },{
        name: 'Testing',
        type: 'move_testing',
        clickAction: 'move_testing',
        class: 'option-btn option-small-icon',
        disabled: false,
        completed: false,
        image: "",
        btnSub: [],
    },
]

