import { OnInit, Component, Input,ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { Trello, TrelloCard, TrelloChecklist } from '../../classes/trello';
import { TrelloService } from '../../services/trello.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trello-card',
  templateUrl: './trello-card.component.html',
  styleUrls: ['./trello-card.component.css']
})
export class TrelloCardComponent implements OnInit, OnDestroy {
    trelloInfo!: Trello;
    trelloSub: Subscription;


    constructor(
      private trelloService: TrelloService,
      private detectChanges: ChangeDetectorRef, 
      private renderer: Renderer2
    ){
      this.trelloSub = trelloService.trello$.subscribe(newTrello => {
        this.trelloInfo = newTrello;
        this.detectChanges.markForCheck();
      });
    }

    @Input() css: any = null

  ngOnInit(): void{
    this.renderer.addClass(document.body, 'modal-open');

    const currentTrelloInfo = this.trelloService.currentTrelloInfo();
    const activeCard = currentTrelloInfo.active_trello_card;

    //console.log(activeCard, '<=== active card capture!@#@!#')

    const checkChecklists = activeCard[0].idChecklists
    if(checkChecklists){
      const request = this.getChecklists(checkChecklists, 'onInit')

    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  getChecklists(checklists: string[], from: string){
    const requests: string[] = [];
      //Run Batch Request
    checklists.forEach(checklists => {
      requests.push(this.trelloService.buildGetPath('cards', checklists));
    })
   
    const batchRequest = this.trelloService.getBatchRequest(requests);
    return batchRequest
  }

}

