import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, catchError, find, findIndex, forkJoin, observable, retry } from 'rxjs';
import { CURRENT_DRIVERS, DEFAULT_EMAIL_ORDER, DEFAULT_ROUTE_CHANGES, DEFAULT_TOOL_ROUTE, DEFAULT_VENDORS, Driver, EmailOrder, RouteChange, RouteDragDrop, DragItem, TESTING_DRIVER, ToolRoutes, RouteBtnContextView, DEFAULT_ROUTE_OPTIONS_BUTTON,  } from './classes/tool-routes';
import { CARD_DAILY_MAINTENANCE, ROUTE_CARD, TrelloCard, TrelloCheckItem, TrelloChecklist, TrelloLabel, } from 'src/app/api/classes/trello';
import { TrelloService } from 'src/app/api/services/trello.service';
import { AccountService } from '../../services/accounts.service';
import { DefaultAppConfig } from 'src/app/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ToolRoutesService {
  token = localStorage['trello_token'];
  key = '097d608a9c59562645c3fc58675e770e';

  idBoardJRG = "5ceb1acc784b294a81c1e647";
  idBoardHolding = '5ea969ad22a3425649825fa5';
  idBoardTesting = '609a6bc7ed92fd8bec9c3c08';
  idListTitg = '652da076674df197bee13726';
  idListRNS = "5eb80fee9cbbb947fcc06cdb";

  idBoardTeam = "5d9d218d17b87227bfe9980a";
  idListTeam = "5d9d21938f451d773ed3b1ef";

  subjectToolRoute = new BehaviorSubject(DEFAULT_TOOL_ROUTE);
  routeView$ = this.subjectToolRoute.asObservable();

  subjectEmailOrder = new BehaviorSubject(DEFAULT_EMAIL_ORDER);
  emailOrder$ = this.subjectEmailOrder.asObservable()

  constructor(
    private trelloService: TrelloService, 
    private accountService: AccountService,
    private http: HttpClient,
  ) {}
  
  currentRouteInfo(): ToolRoutes { return this.subjectToolRoute.value};
  nextRouteInfo(views:  ToolRoutes, from: string) { this.subjectToolRoute.next(views)};

  currentEmail():EmailOrder[]{return this.subjectEmailOrder.value};
  nextEmail(email: EmailOrder[], from:string){this.subjectEmailOrder.next(email)};

  setView(what: string, to:any, data: any, from:string){
    const currentView = this.currentRouteInfo()

    const optionsCheck = 'show_email' + 'show_drivers' + 'show_confirm_window' + 'show_info';
    
    if(optionsCheck.includes(what)){currentView.view.routeBtnContextView.showBtnOptions = this.fillActiveBtnArray(null)};

    switch(what){
      case 'show_board': currentView.view.showBoard = to; break;
      case 'show_info': currentView.view.trelloView.showCard = to; currentView.view.showBackground = to;currentView.view.showOptions = false;  break;
      case 'show_email': 
        currentView.view.showEmailOrder = to; 
        currentView.view.showBackground = to;  
        currentView.view.routeBtnContextView.showBtnOptions = this.fillActiveBtnArray(null);
        currentView.view.showOptions = false;
        currentView.view.showConfirmWindow = false;
        break;
      case 'show_drivers': currentView.view.showDrivers = to; break;
      case 'show_confirm_window': {
        currentView.view.showConfirmWindow = to; 
        currentView.view.showBackground = to; 
        currentView.view.showOptions = to;
        if(data){currentView.view.confirmWindow = data };

        break; 
      }

      case 'show_btn_options': 

        currentView.view.routeBtnContextView.showBtnOptions=to;
        currentView.view.showBackground = currentView.view.showBackground ? false: true;
        
        const newRouteBtnContextView = new RouteBtnContextView;

        newRouteBtnContextView.btnContextMenuInfo = data.btn;
        newRouteBtnContextView.showBtnOptions = data.showBtnOptions;
        newRouteBtnContextView.domRect = data.domRect;
        currentView.view.showOptions = true;

        currentView.view.routeBtnContextView = newRouteBtnContextView;
      break;

      case 'loading': currentView.view.isLoading = to; break;

      case 'mini': 
        if(typeof data === 'number' && data === -1){  
          for (let index = 0; index < currentView.activeDrivers.length; index++) {currentView.trackerMini[index] = true;}}
        else{ currentView.trackerMini[data] = currentView.trackerMini[data] ? false: true; };
      break;

      case 'dismiss': 
        currentView.view.showConfirmWindow = false;
        currentView.view.showEmailOrder = false;
        currentView.view.showOptions = false;
        currentView.view.showBackground = false;
        currentView.view.trelloView.showCard = false;
        currentView.view.showPictures = false;
        currentView.view.routeBtnContextView.showBtnOptions = this.fillActiveBtnArray(null);
      break;
    }
    
    this.nextRouteInfo(currentView,"routeService");
  }

  // MARK - Making Routes (Mapping and moving)
  moveCards(post: any, from: string){
    
    post.subscribe({
      next: (data: any[]) => {
        this.refreshAllBoards();
       },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
    
  }

  movePendingToTitg(){
    const idBoardHolding = ''
    const idListPending = '5ea969ad22a3425649825fa5';
    const idListTitg = this.idListTitg;

    const requestPending = this.trelloService.getRequest('list_card', idListPending );

    if(requestPending){
      requestPending.subscribe(

      )
    }

    // Get all Pending List
  }

  moveDragItems(dropData: RouteDragDrop, type: string){   
    /*function routeCheck(dragItem: DragItem){
      let returnCheck = false;
      const dragName = dragItem.name;
      
      const includeList = ['pickup', 'drop', 'carry', 'p&p', 'pickup & pay', 'build', 'load', 'return', 'returns'];

      includeList.forEach(check => {if(dragName.includes(check)){returnCheck = true}});
      return returnCheck;
    }*/

    const postItems = dropData.postItems;
    const driver = dropData.driver;

    // Setup observable and Route Changes
    let postData: Observable<Object>[] = [];
    const routeChanges: RouteChange[] = [];
 

    // Index counter
    let index = 0;'';
    let pos: any = 'bottom';
    
    postItems.forEach(dragItem => {
      // Setup RouteChange Tracker;
      let newRouteChange = new RouteChange;
      newRouteChange.indexCardDriver = dropData.indexCardDriver;
      
      // Move Logic, 2 parts, 1 part make a new checklist item in the drivers route and the second update the checklist on the card to say to was routed
      /*const idBoard = driver.trelloInfo.idListDriverRoute;
      const idChecklistRoute = driver.routeCard.idChecklists[0];
      const idChecklistCard = dragItem.idChecklist;
      const idCheckItem = dragItem.idCheckItem;

      let passDropData = dropData;
    */
      if(dropData.nextIndex !== -1 && dropData.previousIndex !== -1){ 
        pos = this.trelloService.setPos(dropData, type);
      } else if (postItems.length > 1) { pos = 'bottom' }
      else{ 
        pos = index * 10000 + 100000000;
        dropData.nextIndex = index;
      };
 
      const newPostData = this.setupItemToItemCreation(type,driver,dragItem,dropData,pos,'moveDragItems');
     
      postData = [...postData, ...newPostData];

      index++;
    });

    // Move the route card to the driver board during mass move
    if(dropData.dragCard.length > 0){
      const idList = dropData.driver.trelloInfo.idListDriverCards;
      const idBoard = dropData.driver.trelloInfo.idBoardDriver;
      const idCard = dropData.dragCard[0].idCard;

      const moveCardData= {idList: idList, idBoard: idBoard};
      postData.push(this.trelloService.putRequest('move_card',idCard, null, null, moveCardData)); 
    }
    
    const request = forkJoin(postData);
    if(request){this.generalSubscription(request, routeChanges, type) };    
  }

  generalSubscription(request: any, routeChanges: RouteChange[]|null, from: string){

    request.subscribe({
      next: (data: any) => { 
        this.refreshAllBoards();      
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    } )
  }

  // MARK - MAPPING Stuff
  mapRouteCard(data: any[], from: string){
    const driverRoutes = data.map(el =>{return el['200']})

    const currentRoutes = this.currentRouteInfo();
    let i = 0
    currentRoutes.activeDrivers.forEach((driver: Driver) => {
      driver.routeCard = driverRoutes[i]
      i++
    });
    
    this.nextRouteInfo(currentRoutes, "mapRoutes")
  }  
  
  mapActiveCardtoDrag(data: any, driver: Driver, from: string): DragItem[]{
    const newDragItems: DragItem[] =[];

    let checkItems: any = null;

    if(data[0]&&data[0]['checkItems']){ checkItems = data[0]['checkItems']}
    else if(data['checkItems']){ checkItems = data['checkItems']}
    else{checkItems = data};
    if(checkItems){
      checkItems.forEach((el: TrelloCard) => {
        let pos = -1;
        if(typeof el.pos === 'number' ){pos = el.pos};
        const newDragCity = this.mapActiveDriverDragItem(el, driver, pos, 'mapActiveCardtoDrag');
        
        newDragItems.push(newDragCity);
      });
    }

    newDragItems.sort((a,b) =>a.pos - b.pos);

    return newDragItems;
  }

  mapTitgCatalog(cards: TrelloCard[], checklists: any[]): DragItem[]{
    const newCardCatalog: DragItem[] = [];

    for(let i = 0; i < cards.length; i++){
      // General Setup
      const currentCard = cards[i];

      let newCCItem = new DragItem;
      const dragItems: DragItem[] = [];

      // Map each checklist to a DragItem
      if(checklists[i] && checklists[i].length > 0){      
        checklists[i].forEach((checklist: TrelloChecklist) => {
          // MARK - Push the completed drag Item
          dragItems.push(this.mapTitgDragItem(currentCard, checklist, 'titg'));
        });
      } else {
        console.log('Initiate 666 protocol....');
        const emptyChecklist = new DragItem;
        emptyChecklist.idCard = '666';
        dragItems.push;
      }

      const  labelSearchArrayValues= ['DROP','CARRY', 'INFO'];

      // Setup/Make Drop Item
      const dropLabel = this.searchTrelloLabels(currentCard.labels, labelSearchArrayValues, 'makeTrelloChecklist');

      if(dropLabel && dropLabel.name){
        const newChecklist = new TrelloChecklist;
        newChecklist.name = dropLabel.name;
        const dragItem = this.mapTitgDragItem(currentCard, newChecklist, 'titg');
        dragItem.pos = 999999999999;

        //console.log(" Drop lables!@#!@#", dropLabel)

        dragItems.push(dragItem);
      } else {    
        console.error("Missing drop LABEL")
        const dragItem = new DragItem

        dragItem.displayName = 'Missing drop LAbel!';
        dragItem.name ='Missing drop LAbel!';
        dragItem.action = 'mia-drop-label'
        dragItem.driver = 'TBD';
        dragItem.pos = 999999999999;
        dragItems.push(dragItem);

      }

      // Card Map
      newCCItem = this.mapTitgDragItem(currentCard, null, 'newCCitem')

      // Sort Drag Items
      //const sortedChecklistInDragItems = dragItems.sort((a,b) =>a.pos - b.pos);

      //newCCItem.dragItems = sortedChecklistInDragItems;
      newCCItem.dragItems = dragItems;

      newCCItem.dueComplete = currentCard.dueComplete;
      newCCItem.action = 'card';
      newCardCatalog.push(newCCItem);
    }    

    return newCardCatalog
  }

  mapTitgDragItem(card: TrelloCard, checklist: TrelloChecklist|null, from: string):DragItem {
    // Discover Action and Location 
    let passName = '';
    if(!checklist){passName = card.name}else{passName = checklist.name}
    
    // Setup the Drag item by mapping Action Location Result to a DragItem
    const dragItem: DragItem = this.mapActionLocation(passName, card.name, card.labels, null, 'mapTitgDragItem');
    //this.newMapActionLocation(passName, card.name, card.labels, 'mapTitgDragItem')
    
    if(dragItem.idCard === ''){dragItem.idCard = card.id};
   
    if(checklist){
      dragItem.idChecklist = checklist.id;
      dragItem.checkItems = checklist.checkItems;
    } 

    if(checklist && typeof card.pos === 'number' && checklist){dragItem.pos = checklist!.pos} 
    else if(card && typeof card.pos === 'number'){dragItem.pos = card.pos!};

    // Assign Basic Stuff
    dragItem.state = card.state;
    dragItem.idChecklists = card.idChecklists;
    
    
    
    // Assign Blank elements for the amount of idChecklist 
    dragItem.url = "https://trello.com/c/" + dragItem.idCard;

    let nameSearch = '';

    if(checklist){nameSearch = checklist.name}else{nameSearch = card.name!}
    if(dragItem.name.toLowerCase().includes('(r)')){ dragItem.routed = true; dragItem.disableDrag = true;};

    if(card.labels){dragItem.labels = card.labels};
    if(card.idLabels){dragItem.idLabels = card.idLabels};

    // Check for team board
    const idBoard = card.idBoard;
    if(idBoard){ dragItem.idBoard = idBoard; if(idBoard === this.idBoardTeam){dragItem.team = true; dragItem.disableDrag = true}};

    const idList = card.idList;
    if(idList){dragItem.idList = idList};

    if(dragItem.name === ''){console.log('failed name setup!@#!@#', dragItem)}

    return dragItem;
  }

  mapActiveDriverDragItem(data: any, driver: Driver, pos: number, from: string): DragItem {
    
    let dragItem = new DragItem;

    if(data.id && data.idBoard){  dragItem = this.mapCardtoDragItem(data)} // Trello Card
    else if(data.id && !data.idBoard){
      dragItem.idCheckItem = data.id;
      const checkLinkRegex = /\bhttps:\/\/trello\.com\/c\/([A-Za-z0-9]{24}$)\b/g;
      const checkShortLinkRegex =  /\bhttps:\/\/trello\.com\/c\/([A-Za-z0-9]{8}$)\b/g

      const checkLink = data.name.match(checkLinkRegex);
      const checkShortLink = data.name.match(checkShortLinkRegex);

      if(checkLink || checkShortLink){
        // Map Action, Card ID, and location 
          dragItem = this.mapActionLocation(data.name, data.name, data.labels, driver,  'mapActiveDriverDragItem');
      } else {
        dragItem.displayName = data.name;
        dragItem.name = data.name
      };
      
      dragItem.pos = pos;
      dragItem.name = data.name.trim();
    } else{
      console.log("<------------------What is this funny thing ---------------->");
    }

  
    // ID's data assignments
    if(data.state){dragItem.state = data.state; if(dragItem.state === 'completed'){dragItem.dueComplete = true}};
    if(data.idList){dragItem.idList}
    if(data.idChecklist){ dragItem.idChecklist = data.idChecklist};
    if(data.id){dragItem.idCheckItem = data.id};
    if(data.shortUrl){dragItem.shortUrl = data.shortUrl}
    
    if(data.name.toLowerCase().includes('(o)')){dragItem.ordered = true};
   

    // Driver assignments
    if(driver){ 
      if(driver.routeCard.idChecklists[0]){dragItem.idRouteChecklist = driver.routeCard.idChecklists[0]}

      dragItem.idDriver = driver.driverInfo.idDriver;
      dragItem.driver = driver.driverInfo.firstName;
    };

    //if(dragItem.action.toLowerCase().includes('main')){console.log(dragItem, " HMMMMMMMMMMMMMMMMMMMMMMMM")}

    return dragItem;
  }

  mapIds(trelloObjects: any[], from: string): string[]{
    let mapObject = trelloObjects; 
    if(from === 'titg' || from === 'activeDrivers'){
      const mapIds = mapObject.map( object => object['idChecklists']);
      const filteredIds = mapIds.filter(object => object.length >0);
      return filteredIds
    }
    const mapIdString = mapObject.map( object => object['id']);
    return mapIdString;
  }

  mapActionLocation(passedChecklistName: string, passedCardName: string, trelloLabels: TrelloLabel[], driver: Driver|null, from: string): DragItem{
    function splitClientCompanyLocation(action: string, newDisplayName: string, passedString: string[]){
      dragItem.action = action;
      dragItem.clientName = passedString[0];
      if(passedString.length === 3){ dragItem.companyName = passedString[1]};
      dragItem.location = passedString[passedString.length-1];

      dragItem.name = newDisplayName;
      dragItem.displayName = newDisplayName;
    };

    function checkForCardIdAndUrl(checkString: string): any{
      const idCheckRegex = /https:\/\/trello\.com\/c\/[a-zA-Z0-9]{24}$/;
      const idCheckMatch = checkString.match(idCheckRegex);

      const idCheckShortlinkRegex = /https:\/\/trello\.com\/c\/[a-zA-Z0-9]{8}$/;
      const idCheckShortLinkMatch = checkString.match(idCheckShortlinkRegex);

      let foundUrl = '';
      
      if((idCheckMatch && idCheckMatch[0])){ 
        foundUrl = idCheckMatch[0];
        dragItem.url = foundUrl;
     
      } else if(idCheckShortLinkMatch && idCheckShortLinkMatch[0]){
        foundUrl = idCheckShortLinkMatch[0];
        dragItem.shortUrl = foundUrl;
        //console.log('short url', foundUrl, "------------",idCheckShortLinkMatch, "------------",checkString)
      };
      const idCard = foundUrl.replace('https://trello.com/c/','');

      //console.log(idCard, "<--------------------- ",checkString)

      dragItem.idCard = idCard 
    };

    function checkName(checkString: string){
      const regexName = /\*\*(.*?)\*\*/;
      const matchName = checkString.match(regexName);

      return matchName;
    };

    function checkRoutedAndOrdered(locationString: string): string{
      let displayName = locationString
      // Check for routed, determined by (R)
      if(locationString.includes('(r)')){
        results.routed = true;
        displayName = locationString.replace(/\(r\)/g,'');
      }

      // Check for routed, determined by (R)
      if(locationString.includes('(o)')){
        results.ordered = true;
        displayName = locationString.replace(/\(o\)/g,'');
      }

      return locationString;
    };

    function checkActionPo(checkString: string, labels: string[]): any{
      // PO Check will not work on driver routes
      const actionPoCheck = { action: '', po: ''}

      // Logic, labels should be used as a primary mode of decerning the action
      // Action Check: Pickup
      const checkPickup = /(pickup|p&p|return|load)/;
      if(checkPickup){

        // Need to assign the PO here, if its a return or pickup, this is used for the Emailer
        const splitCardName = passedCardName.split('-');
        if(splitCardName && splitCardName.length > 0){
          const cardDeliveryAddress = splitCardName[splitCardName.length-1];
          const regexPo = /(\d+\s+\w+)/;
          const matchPo = cardDeliveryAddress.match(regexPo);
          if(matchPo && matchPo[0]){ 
            actionPoCheck.po = matchPo[0]
          }
        }

        // Return the found pickup
        actionPoCheck.action = checkString.trim();
      };



      // Action Check: Drop directions      
      const checkDrop = /(carry|drop|boom)/;
      const indexDropCheck = labels.findIndex(el => el.match(checkDrop));
      if(indexDropCheck >= 0){ 
        actionPoCheck.action = labels[indexDropCheck].trim()};

      // Check for Pickup & Drop (Least Likely)
      const checkPD = /pd/;
      const indexPDCheck = labels.findIndex(el => el.match(checkPD));
      if(indexPDCheck >= 0){ actionPoCheck.action = labels[indexPDCheck].trim()};

      return actionPoCheck;

    };

    function checkLocation(passString: string, cardName: string): string{
      
      let location = passString.toLowerCase();

      const locationSearch = /(?<=@|:)(.+)/
      const locationResults = location.match(locationSearch);

      const dropCheck = /(carry|drop|boom)/;
      const dropCheckResults = location.match(dropCheck);

      const checkTitle = location.includes('-');
      const cardCheck = !locationResults && !dropCheckResults && checkTitle;


      if(!passString){ return 'Starting Location'}
      else if(locationResults && !dropCheckResults && !passString.includes('http')){
        let location = locationResults[0].slice(1,locationResults[0].length);
        if(location.includes(':')){location = location.replace(/^(.*?):.*/, '$1')};

        const checkRouted = location.includes('(r)')
        if(checkRouted){ location.replace(/\s*\(.*?\)\s*/, '')};

        location = location.trim();

        // Future ADD: If this fails need a function to check a mapping software that will find the address
      }else if(dropCheckResults || cardCheck){
        const lastIndex = cardName.lastIndexOf('-');
        const address = cardName.substring(lastIndex + 1, cardName.length).trim();
        location = address;


      } 
      // Likely going to catch Checklist items
      else if(passString.includes('@') && passString.includes('https') ){

        const regex = /@ (.*?) https:\/\//;
        const foundLocation = passString.match(regex);
        
        if(foundLocation && foundLocation[1]){ location = foundLocation[1].trim()} else { return ''};
      }
      
      location.replace('**','');

      // Remove (o) && (r) Tag && set ordered and route
      if(location.includes('(r)')){dragItem.routed = true}
      location = location.replace('(r)','');

      if(location.includes('(o)')){dragItem.ordered = true}
      location = location.replace('(o)','');

      // Replace anything that has brakets left over
      location = location.replace(/\(.*?\)/g, '');

      if(location.includes(dragItem.url)){ location = location.replace(dragItem.url,'')}

      return location.trim();
    };

    let results = { 
      name: '',
      action: '', 
      displayName: '',
      idCard:'', 
      location: '', 
      po: '',
      disableDrag: false, 
      url: '',

      ordered: false,
      routed: false,
    }; 

    const dragItem: DragItem = new DragItem;
    if(passedChecklistName === '**Clean up that van!**' || passedCardName === 'Clean up that van!'){console.log("Display time!")}
    // Mapping active driver and Titg drag
    if(from === 'mapActiveDriverDragItem' || from === 'mapTitgDragItem' ){
      results.displayName = passedChecklistName + " " + passedCardName; 

      let displayName = '';
      displayName = passedChecklistName.toLowerCase();

      // This will pass with Checklist names that are not maintenance
      if(displayName){
        // Split the string based on the @ symbol with the action on the left and the location & url on the right
        const splitString = displayName.split('@');
        let actionString = '';
        let locationString = '';
        
        // Custom drag item setup
        let customDrag = false;

        if(splitString && splitString.length >1){ // Standard header with format: Client name - company name - delivery address
          //console.log(splitString, "<-------")
          actionString = splitString[0].replaceAll('**','');
          locationString = splitString[1].replaceAll('**','');
        } else if(splitString && splitString.length === 1){
          if(splitString[0].includes('http')){ // Maintenance Cards 
            checkForCardIdAndUrl(splitString[0]);


            const checkName = dragItem.name;
            const removeUrl = displayName.replace('https://trello.com/c/' + dragItem.idCard, '').trim()
            
            //console.log(removeUrl,"-----------0-0-0-000000-0",dragItem, "Mic check I can feel it!", passedChecklistName,  "<------------", checkName )
            if(!removeUrl){
              dragItem.name = 'Van Maintenance - Daily';
              dragItem.displayName = 'Van Maintenance - Daily';
              dragItem.action = 'Daily Maitenance';
              dragItem.location = 'Starting Location';

              if(!dragItem.url){dragItem.url = dragItem.shortUrl}
              
              return dragItem;
            } else {
              dragItem.displayName = displayName;
              dragItem.name = displayName;
              checkForCardIdAndUrl(displayName);
              const resultsActionPo = checkActionPo(displayName, [])
              
              if(resultsActionPo.action){ dragItem.action = resultsActionPo.action}
              if(resultsActionPo.po){ dragItem.po = resultsActionPo.po}
              return dragItem;
            }
            


          } else { // Trello Card header or Drop Drag item
          
            const advancedSplit = displayName.split('-');

            if(advancedSplit && advancedSplit.length > 1){ // Drop Drag Item
              const dropCheckRegEx = /(carry|drop|boom|return)/;
              const dropCheck = advancedSplit[1].match(dropCheckRegEx);

              if(dropCheck && dropCheck.length > 0){
                // Drop Case
                const nameClientLocation = passedCardName.split('-')
                splitClientCompanyLocation(displayName, displayName, nameClientLocation)
                return dragItem;
              } else if(advancedSplit.length >= 2 &&advancedSplit.length <= 3){
                splitClientCompanyLocation(displayName, displayName, advancedSplit)
                
                return dragItem;
              }
            }

            dragItem.action = 'Custom route item'
            dragItem.location = '';
            
            if(!displayName.includes('https')){
              dragItem.url = '';
              dragItem.name = displayName;
              dragItem.displayName = displayName;
              dragItem.action = displayName;

              return dragItem;
            }
          }


        } else { 
          console.error('action and location are a miss',displayName,passedCardName,passedChecklistName)
        }

        // Clear ordered and routed
        //displayName = displayName.replace(/\(.*?\)/g, '');

        // Check routed and ordered and clear it from the display string
        displayName = checkRoutedAndOrdered(displayName)

        let labels: string[] = [];

        if(trelloLabels && trelloLabels.length>0){labels = trelloLabels.map(el=> el.name)};

        // Get Check ID from the linked card
        const resultsId = checkForCardIdAndUrl(locationString);

        if(resultsId && resultsId.idCard){
          results.idCard = resultsId.idCard;
          results.url = 'https://trello.com/c/' + resultsId.idCard;
          locationString = locationString.replace(results.url,'');
        };

        results.displayName = displayName.replace(results.url, '');
        results.displayName = displayName.replaceAll('**', '');
        //if(displayName.includes('drop')){console.error(results.displayName, "<--------- maintenance will be empty", splitString )}
        
        if(!customDrag){
            // Map Driver Action        
            const actionPo = checkActionPo(actionString,labels);
            if(actionPo.action){results.action = actionPo.action};
            if(actionPo.po){results.po = actionPo.po}

            // Special Case Maintenance: Disable drag and change name
           // if(results.action.toLowerCase().includes('maintenance')){
             // results.disableDrag = true;
              //console.log("This is the maintenance case",passedCardName,passedChecklistName);

          //  }

            // Map Action Location
            const resultsLocation = checkLocation(locationString, passedCardName);
           
            if(resultsLocation){ results.location = resultsLocation };
           
        }
       
      }

    } else if (from === 'mapCardtoDragItem'){
    // This is a roomCard and should be treated as such
      results.action = 'RoomCard';
      results.displayName = passedChecklistName;
      results.name = passedChecklistName;
      
      if(results.displayName.toLowerCase().includes('maintenance')){results.disableDrag = true}
      //console.log(results, " Missing the checklist", passedCardName,passedChecklistName, from);
    } else {
      console.error("This is the fail case", results, " Missing the checklist", passedCardName,passedChecklistName, from )
    }

    if(results.url && !dragItem.url){dragItem.url = results.url};
    if(results.action){dragItem.action = results.action};
    if(results.displayName){ dragItem.displayName = results.displayName; dragItem.name = results.displayName};
    if(results.location){ dragItem.location = results.location};
    if(results.disableDrag){dragItem.disableDrag = results.disableDrag};

    if(dragItem.shortUrl.includes('ordnybur')){ console.error('something blue and bad', dragItem)}

    if(!dragItem.displayName){console.log('missing display')}

    return dragItem;
  }

  mapCheckItemtoDrag(checkItem: TrelloCheckItem, dragIem: DragItem): DragItem{
    dragIem.idCheckItem = checkItem.id;
    dragIem.idChecklist = checkItem.idChecklist;
    dragIem.pos = checkItem.pos;
    dragIem.state = checkItem.state;
    dragIem.name = checkItem.name;

    return dragIem;
  }

  mapRouteChange(what: string, dropData: RouteDragDrop ,from: string):RouteChange{
    const defaultRouteChange = DEFAULT_ROUTE_CHANGES;
    let newRouteChange = new RouteChange;

    const routeChange = defaultRouteChange.find(el => el.changeType === what);
    
    if(routeChange){
      routeChange.from = from;
      routeChange.indexCardDriver = dropData.indexCardDriver;
      routeChange.indexCheckItem = dropData.nextIndex;
      routeChange.indexDragItem = dropData.nextIndex;

      return routeChange;
    }

    return newRouteChange;
  }

  mapCardtoDragItem( trelloCard: TrelloCard): DragItem{
    const dragItem: DragItem = this.mapActionLocation(trelloCard.name, trelloCard.name, trelloCard.labels, null,  'mapCardtoDragItem');

    dragItem.idCard = trelloCard.id;
 
    dragItem.name = trelloCard.name.trim();
    dragItem.idList = trelloCard.idList;
    dragItem.idBoard = trelloCard.idBoard;
    dragItem.idChecklists = trelloCard.idChecklists;

    dragItem.labels = trelloCard.labels;
    
    dragItem.dueComplete = trelloCard.dueComplete;
    dragItem.idLabels = trelloCard.idLabels;
    dragItem.url = trelloCard.url;
    dragItem.state = trelloCard.state;

    if(typeof trelloCard.pos === 'number' ){dragItem.pos = trelloCard.pos};

    // Map Action, Card ID, and location 
    const resultsActionLocation = 
    //this.newMapActionLocation(trelloCard.name, trelloCard.name, trelloCard.labels,  'mapCardtoDragItem')

    dragItem.url = "https://trello.com/c/" + dragItem.idCard;
    
    return dragItem;
  }

  mapTrelloCardToDragItem(driverTrelloCards: any[]){
    console.log("is thios firing or not", driverTrelloCards)
    const currentActiveDrivers = this.currentRouteInfo().activeDrivers;

    for (let indexDriver = 0; indexDriver < currentActiveDrivers.length; indexDriver++) {
      const driver = currentActiveDrivers[indexDriver];
      driver.dragItems.forEach((dragItem:DragItem) => {
        if(!dragItem.displayName){ dragItem.displayName = dragItem.name};
        
        // Regex to check for drop
        const regexDrop = /(carry|drop|boom)/;
        const checkDrop = dragItem.action.match(regexDrop);

        if(dragItem && dragItem.name && dragItem.name.includes('https') && dragItem.displayName && dragItem.action){
          if(driverTrelloCards[indexDriver][0] &&
            driverTrelloCards[indexDriver][0].name.toLowerCase()) {   
              // Replace Machine readable title with HUMAN readable
              dragItem.displayName = dragItem.displayName.replace(dragItem.url, ( " - " + driverTrelloCards[indexDriver][0].name.toLowerCase()));            
              
              // Replace long url with Short url
              if(driverTrelloCards[indexDriver][0].shortUrl){ dragItem.shortUrl = driverTrelloCards[indexDriver][0].shortUrl};

              // Push new info and del first object
              dragItem.associatedTrelloCard.push(driverTrelloCards[indexDriver][0]);
              driverTrelloCards[indexDriver].shift();
          }
        
        }

        if(checkDrop){}
      });
    }
  
  }

  // MARK - Implement
  implementRouteChanges(routeChanges: RouteChange[], data: any, from: string){
    const newRouteInfo = this.currentRouteInfo();

    let index = 0;
    routeChanges.forEach(routeChange => {

      const indexCardDriver = routeChange.indexCardDriver;
      const indexDragItem = routeChange.indexDragItem;
      const fromRouteChange = routeChange.from;
      const changeType = routeChange.changeType;

      switch(changeType){
        case 'update_checklist':
          if(fromRouteChange === 'moveTitgtoDriver'){
            const newChecklist: TrelloChecklist = data[index];
            const card = newRouteInfo.cards[indexCardDriver];
            const newTitgDragItem = this.mapTitgDragItem( card, newChecklist,'implementRouteChanges:update_checklist:moveTitgtoDriver');
            
            newRouteInfo.titgCardCatalog[indexCardDriver].dragItems[indexDragItem] = newTitgDragItem;
          }
        break;
        case 'add_checkItem':
          if(fromRouteChange === 'moveTitgtoDriver'){
            const defaultDragItem = new DragItem;
            const newDragItem = this.mapCheckItemtoDrag(data[index], defaultDragItem );
            
            newRouteInfo.activeDrivers[indexCardDriver].dragItems[indexDragItem] =newDragItem;
          }

          if(fromRouteChange === "moveDrivertoDriver"){
            const oldCheckItem = newRouteInfo.activeDrivers[indexCardDriver].dragItems[indexDragItem];
            const newCheckItem: TrelloCheckItem = data[index];


            const newDragItem = this.mapCheckItemtoDrag(newCheckItem, oldCheckItem );

          
            newRouteInfo.activeDrivers[indexCardDriver].dragItems[indexDragItem] = newDragItem;
          }
      }
      index = index + 1;
    });

    this.nextRouteInfo(newRouteInfo, 'implementRouteChanges')
  }

  implementTeamCreation(drivers: Driver[], dragItem: DragItem){
    // Driver 0 is the card holder and Driver 1 is the card recepient
    const driverFirst = drivers[0];
    const driverSecond = drivers[1];

    // Get the name of drag item and add 'w/(driver)'
    const nameCheckItem = dragItem.name.trim();
    const insertIndex = this.trelloService.findLinkIndex(nameCheckItem) - 3;

    const nameFirstDriver = nameCheckItem.slice(0,insertIndex) + ' w/' + driverSecond.driverInfo.firstName + nameCheckItem.slice(insertIndex);
    const nameSecondDriver = nameCheckItem.slice(0,insertIndex) + ' w/' + driverFirst.driverInfo.firstName + nameCheckItem.slice(insertIndex);
      
    // Setup overwrite driver one and create driver two

    // PUT: Ammend first driver checkItem **COMPLETED**
    const idCardFirstDriver = driverFirst.routeCard.id;
    const idCheckItem = dragItem.idCheckItem;
    const dataFirstDriver = {name: nameFirstDriver}

    const putFirstDriver = this.trelloService.putRequest('update_check_item', idCardFirstDriver, null, idCheckItem, dataFirstDriver);

    
    // POST: Create second driver checkItem **COMPLETED**
    const idChecklistSecondDriver = driverSecond.routeCard.idChecklists[0]
    const dataSecondDriver = {pos: 'bottom', idChecklist: idChecklistSecondDriver, name: nameSecondDriver}

    const postSecondDriver = this.trelloService.buildPostPath('add_checkItem', driverSecond.routeCard.idChecklists[0], dataSecondDriver);

    // PUT: Move card to team board **COMPLETED**
    const defaultTrelloBoards = TESTING_DRIVER[0].trelloInfo;

    const idChecklistTeam = defaultTrelloBoards.idListDriverRoute;
    const idBoardTeam = defaultTrelloBoards.idBoardDriver;
    const idCard = dragItem.idCard;

    const dataMoveTeamCard= {idList: idChecklistTeam, idBoard: idBoardTeam};
    const putTeamCard = (this.trelloService.putRequest('move_card',idCard, null, null, dataMoveTeamCard)); 
  
    // Need to change and create new dragItems
    //this.trelloService.generateTrelloCheckItem();
    const post = forkJoin([putFirstDriver,postSecondDriver,putTeamCard]);
    this.generalSubscription(post, null,'implementTeamCreation');
  
  }

  // MARK - Creation
  makeTrelloChecklist(card: TrelloCard, from: string): TrelloChecklist{
    const newChecklist: TrelloChecklist = new TrelloChecklist;

    if(from === 'drop'){
      if(card && card.name){
        newChecklist.idBoard = card.idBoard;
        newChecklist.idCard = card.id;

        let dropLabel = this.searchTrelloLabels(card.labels, ['DROP','CARRY'], 'makeTrelloChecklist');

        if(dropLabel){newChecklist.name = dropLabel.name + " " + card.url} 
        else { newChecklist.name = "** MISSING DROP LABEL ** "}
      }     
    }

    //newChecklist.name = this.formatBoldText(newChecklist.name);
    return newChecklist
  }
  
  makeRouteMaintenanceCard(): TrelloCard[]{
    const routeMaintenanceCards:TrelloCard[] = [];

    for(let i = 0; i < TESTING_DRIVER.length; i++) {
      const makeRouteMaintenanceCard: TrelloCard = CARD_DAILY_MAINTENANCE;
      TESTING_DRIVER[i].trelloInfo.idBoardDriver
      routeMaintenanceCards.push(makeRouteMaintenanceCard)
    }

    return routeMaintenanceCards;
  }

  makeRouteCard(maintenanceId: string[]): TrelloCard[]{
    // Get Drivers
    const drivers: Driver[] = TESTING_DRIVER;
    
    const routeCards: TrelloCard[] = []


    const newDate = new Date

    drivers.forEach(driver => {
      const routeCard = new TrelloCard;

      //routeCard.idList = driver.idListDriverHolding;

    });
    return routeCards
  }

  makeCustomCheckItem(driverIndex: number, dragItem: DragItem, idCheckist: string, data: any){
    const request = this.trelloService.buildPostPath('add_checkItem', idCheckist, data);
    request.subscribe({
      next: (data: any) => { 
        const newRouteInfo = this.currentRouteInfo();
        const currentDriver = newRouteInfo.activeDrivers[driverIndex];

        const newDragItem = this.mapCheckItemtoDrag(data, dragItem);

        currentDriver.dragItems.push(newDragItem);
        newRouteInfo.activeDrivers[driverIndex] = currentDriver;

        this.nextRouteInfo(newRouteInfo, 'makeCustomCheckItem');
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
  }

  delCustomCheckItem(from: string, driverIndex: number, indexDragItem: number, idChecklist: string, idCheckItem: string){
    const request = this.trelloService.delRequest('del_checkItem', idChecklist, idCheckItem);
    request.subscribe({ 
      next: (data: any) => { 
      const newRouteInfo = this.currentRouteInfo();
      const currentDriver = newRouteInfo.activeDrivers[driverIndex];
      newRouteInfo.activeDrivers[driverIndex] = currentDriver;
      
      currentDriver.dragItems.splice(indexDragItem, 1);

      this.nextRouteInfo(newRouteInfo, 'delCustomCheckItem')
    },
    error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
  

    })
  }

  // MARK - Updated
  updateCard(type: string, indexes: any, data: any){
    let driverIndex = -1;
    let indexDragItem = -1; 

    const newRouteView = this.currentRouteInfo();
    switch (type){
      case 'update_check_item':
        // Index assignment  
        driverIndex = indexes.driverIndex;
        indexDragItem = indexes.indexDragItem; 
        
        newRouteView.activeDrivers[driverIndex].dragItems[indexDragItem].state = data.state;
        newRouteView.activeDrivers[driverIndex].dragItems[indexDragItem].pos = data.pos;
      break;

      case 'update_checklist':
        const type = indexes.type
        // Index assignment, need card and item index
        const cardIndex = indexes.cardIndex;
        const checklistIndex = indexes.checklistIndex;
  
        if(type === 'titg_card'){      
          const currentCard = newRouteView.cards[cardIndex]

          // Build new drag item
          newRouteView.titgCardCatalog[cardIndex].dragItems[checklistIndex] = this.mapTitgDragItem(currentCard, data, 'updateCard:titg_card')
        }
      break;

      case 'update_card':
        // This will update the dueComplete but will in the future update more options
        driverIndex = indexes.driverIndex;
        indexDragItem = indexes.indexDragItem; 

        // Team board catch is -1 on driver index
        if(driverIndex === -1){newRouteView.titgCardCatalog[indexDragItem].dueComplete = data.dueComplete}
        else{newRouteView.activeDrivers[driverIndex].routeCards[indexDragItem].dueComplete = data.dueComplete};
      
        break;
    }


    
    this.nextRouteInfo(newRouteView, 'updateCard');
  }

  // MARK - Build Request
  getTrelloCards(cardData: Driver[]){
    // This is being used to map a url link to the cards name to witch it links to
    // Need to agregate the links and map the name to the correct dragITem

    const getCardData: Observable<any> []= [] 

    // Take driver and iterate for the active dragItems
    cardData.forEach((driver: Driver) => {
      const dragItems: DragItem[] = driver.dragItems;
      const getCardPath: string[] = [];
      dragItems.forEach((dragItem: DragItem) => {
        const idCard = dragItem.idCard;
        if(idCard){getCardPath.push(this.trelloService.buildGetPath('cards',dragItem.idCard))};
      });

      const getBatchCards = this.trelloService.getBatchRequest(getCardPath);
      getCardData.push(getBatchCards);
    });

    const request = forkJoin(getCardData)
    request.subscribe({
      next: (data: any) => {    
        const driveCards: any[] = []
        data.forEach((driverElement:any) => {
          if(driverElement){
            const trelloCards: TrelloCard[] = driverElement.map((el:any)=> el['200']);
            driveCards.push(trelloCards);
          }
        });

        // This is to map the human readable card name to drag item
        this.mapTrelloCardToDragItem(driveCards);
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
  }

  getRequest(ids: string[] | string[][], from: string, request: string): Observable<any>[]| Observable<any>[][]{
    const requestedObservable:Observable<any>[] = []
   
    const objectCheck = typeof ids[0]

    // Run single query
    if(ids.length === 1 && objectCheck === 'string'){
      const batchUrls: string[] = [];
      ids.forEach(id => {if(typeof id === 'string'){requestedObservable.push(this.trelloService.getRequest(request, id))}});
      return requestedObservable;
    }

    // Run batch query
    if(ids.length <= 10 && objectCheck == 'string'){
      const urls: string[] = [];
      ids.forEach((id) => {
        if(typeof id === 'string'){urls.push(this.trelloService.buildGetPath(request, id))}})
      requestedObservable.push(this.trelloService.getBatchRequest(urls))
      return requestedObservable;
    }

    // Run Batch && Fork
    if(ids.length > 10 && objectCheck === 'string'){
      return requestedObservable;
    }

    // Running Multi Tiered Batch && Fork
    if(ids.length > 0 && objectCheck === 'object'){
      const requestedDeepObservable:Observable<any>[] = []

      ids.forEach(idChecklists => {
        const requests: Observable<string>[] = [];

        if(typeof idChecklists === 'object'){
          const urls: string[] = []
          idChecklists.forEach((id: string) => {urls.push(this.trelloService.buildGetPath(request, id))})
          const batchRequest = this.trelloService.getBatchRequest(urls)
          
          requestedDeepObservable.push(this.trelloService.getBatchRequest(urls));
        }
      
        return requestedDeepObservable;
      });
      return requestedDeepObservable
    }
    return requestedObservable;
  }

  getCard(requestId: string, from:string, driverIndex: number){
    let mapRequest = this.getRequest([requestId], from , 'cards');
    if(mapRequest){
      let request: any = null;
      if(mapRequest.length === 1){request = mapRequest[0]};
      if(request){  

        request.subscribe({
          next: (data: TrelloCard) => {
            const trelloView = this.trelloService.currentTrelloInfo();
            trelloView.active_trello_card = [];
            trelloView.active_trello_card.push(data);
            this.trelloService.nextTrelloInfo(trelloView, from);
            this.setView('show_info', true, null,'getCard');
          },
          error: (error: any) => { console.error(error)}
        })   
      }

    }
  }

  getChecklist(cardIndex: number, checklistIndex: number, idCheckist: string, from: string){
    const request = this.trelloService.getRequest('checklists', idCheckist);
    request.subscribe({
      next: (data: any) => {
        const newRouteView = this.currentRouteInfo();

        const newCheckItems: TrelloCheckItem[] =[];
        const mapCheckItems = data.checkItems;

        mapCheckItems.forEach((el: any) => {
          const newCheckItem = this.trelloService.generateTrelloCheckItem(el);
          newCheckItems.push(newCheckItem);
        });

        // Splice in new CheckItems that came in
        newRouteView.titgCardCatalog[cardIndex].dragItems[checklistIndex].checkItems = newCheckItems;

        // Set Open State
        const newtrackerChecklist = newRouteView.trackerChecklist;
        newtrackerChecklist[cardIndex][checklistIndex] = true;
        newRouteView.trackerChecklist = newtrackerChecklist;

        this.nextRouteInfo(newRouteView,"showChecklist")
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
  }

  setAllTrelloInformation(cards: TrelloCard[], from: string, request: string){
    const mapIds = this.mapIds(cards, from);

    if (mapIds){
      const mapRequest = this.getRequest(mapIds, 'getChecklist', request);
      if(mapRequest){
        let request: any = null;

        if(mapRequest.length > 1){request = forkJoin(mapRequest)};
        if(mapRequest.length === 1){request = mapRequest[0]};

        if(request){
          request.subscribe({
            next: (data: any[]) => {
              const cardData: any[] = [];
              const newRouteInfo = this.currentRouteInfo();
              const checkDepth = data[0]['200'];
              
              // Single Card Data
              if(checkDepth){cardData.push(data.map((foo: any) => foo['200']))}

              // Multiple Card Data
              else {
                data.forEach(el => {
                  cardData.push(el.map((foo: any) => foo['200']))
                });
              }
            
              if(from === 'activeDrivers'){
                const activeDrivers = newRouteInfo.activeDrivers;
                //const getCard = cardData.map(el => el.)
                // Get card name associated to drag Item
               

                let i = 0;
                cardData.forEach((el: any) => {      

                  const dragData = this.mapActiveCardtoDrag( el, activeDrivers[i], 'activeDrivers');  
                  activeDrivers[i].dragItems = dragData;
                  i++;
                });

                this.getTrelloCards(activeDrivers);
              }
    
              if(from === 'titg'){
                if(cards instanceof Array){newRouteInfo.cards = cards}
                newRouteInfo.lists = cardData;
                newRouteInfo.titgCardCatalog = [];
                const mapTitg = this.mapTitgCatalog(newRouteInfo.cards, newRouteInfo.lists);

                const titgtrackerChecklist: boolean[][] = []
                const trackerMini: boolean[] = [];
            
                cards.forEach(card => {
                  const trackerChecklist = new Array<boolean>;
                  trackerChecklist.fill(false, card.idChecklists.length);
                  titgtrackerChecklist.push(trackerChecklist); 
                  
                  trackerMini.push(false); // Changes a lot due to testing
                });

                const sortedTitg = mapTitg.sort((a,b) =>a.due.getUTCDate() - b.due.getUTCDate());

                newRouteInfo.titgCardCatalog = sortedTitg;
                newRouteInfo.trackerChecklist = titgtrackerChecklist;
                if(newRouteInfo.trackerMini.length === 0){newRouteInfo.trackerMini = trackerMini};
              }

              this.nextRouteInfo(newRouteInfo, 'getChecklistItemsRequest: ' + from);

             
            },
            error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
          })
        }
      }
      
     
    }
  }

  setDriverTrelloInfo(driverData: any[][]){
    // Filters driver active vs not
    
    const routeCheck: any[] = driverData[0].map( el => el['200'][0]);
    const cardCheck: any[] = driverData[1].map(el=>el['200']);

    if(routeCheck){
      let i = 0;
      const currentRouteInfo = this.currentRouteInfo();

      currentRouteInfo.availableDrivers = [];
      currentRouteInfo.activeDrivers = [];

      const prototypeOptionsTracker: boolean[][][] = []

      routeCheck.forEach((el: TrelloCard|null) => {
        if(el){ 
          const name = el.name;
          prototypeOptionsTracker.push([])
          const nameCheck = currentRouteInfo.activeDrivers.findIndex(driver => driver.routeCard.name === name);
          if(nameCheck < 0){      
            const routeCards: DragItem[] = [];

            const activeDriver = CURRENT_DRIVERS[i];
            activeDriver.routeCard = el;

            const unformatedRouteCards: TrelloCard[] = cardCheck[i];
            if(unformatedRouteCards){
              unformatedRouteCards.forEach(trelloCard => {
                const newDriverRouteCard =this.mapCardtoDragItem(trelloCard)
                newDriverRouteCard.dragType = 'activeDriverCard';

                routeCards.push(newDriverRouteCard)
              });
            }
            activeDriver.routeCards = routeCards;
            //this.showItemOrCard[1].push(true);
  
            currentRouteInfo.activeDrivers.push(activeDriver);
          } else { currentRouteInfo.availableDrivers.push(CURRENT_DRIVERS[i])}
        } else {currentRouteInfo.availableDrivers.push(CURRENT_DRIVERS[i])}
        i++;
      });

      // Qeue up the Checklist
      const activeDrivers = currentRouteInfo.activeDrivers;

      if(activeDrivers.length > 0){
        // Populate 
        //Close Available and open Active boards
        currentRouteInfo.view.routeViewObjects.minorHeaders[2] = false;
        currentRouteInfo.view.routeViewObjects.minorHeaders[4] = true;

        const driverCards = activeDrivers.map(el=>el.routeCard);

        this.setAllTrelloInformation(driverCards, 'activeDrivers', 'checklists');
      }



      const btnOptionsArray = this.fillActiveBtnArray(CURRENT_DRIVERS.length);
      currentRouteInfo.view.routeBtnContextView.showBtnOptions = btnOptionsArray;
      
      this.nextRouteInfo(currentRouteInfo, 'setDriverTrelloInfo');

    }
    
  }  

  setRouteBtnContextView(routeBtnContextView: RouteBtnContextView, from: string){
    const newRouteInfo = this.currentRouteInfo();

    newRouteInfo.view.routeBtnContextView = routeBtnContextView;
  }

  putTrelloItems(request: string, idCard: string | null, idList: string | null, idCheckItem: string | null, data: any,indexes: any, from: string){
    const putRequest = this.trelloService.putRequest(request, idCard, idList, idCheckItem, data);
    if(putRequest){
      putRequest.subscribe({
        next: (data) => {this.updateCard(request, indexes, data)},
        error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
      } )
    }
  }

  // MARK - Search
  searchTrelloLabels(labels: TrelloLabel[], what: string[], from: string): TrelloLabel| null{
    let foundLabel = new TrelloLabel;

    const labelNameArray = labels.map(el => el.name);
    
    what.forEach((searchString:string) => {
      const labelSearchIndex = labelNameArray.findIndex(el =>  el.includes(searchString));
      if(labelSearchIndex > -1){ foundLabel = labels[labelSearchIndex]};
    });

    return foundLabel;
  }

  /*searchVendorLocation(vendorShort: string): any{
    const vendors = DEFAULT_VENDORS;

    const results = vendors.findIndex(vendors => { return vendors.name.toLowerCase() === vendorShort.toLowerCase()});
    if(results > -1){ return vendors[results].location};

    return '';
  }*/

  // MARK - TOOLS
  setupItemToItemCreation(type: string, driver: Driver, dragItem: DragItem, routeDragDrop: RouteDragDrop, pos: string|number, from: string):  Observable<Object>[]{
    /*function routeCheck(dragItem: DragItem){
      let returnCheck = false;
      const dragName = dragItem.name;
      
      const includeList = ['pickup', 'drop', 'carry', 'p&p', 'pickup & pay', 'build', 'load', 'return', 'returns'];

      includeList.forEach(check => {if(dragName.includes(check)){returnCheck = true}});
      return returnCheck;
    }*/

    let postData: Observable<Object>[] = [];
    let routeChanges:RouteChange[] = []

    let checkItemName = '';
    const idChecklistRoute = driver.routeCard.idChecklists[0];
    const idChecklistCard = dragItem.idChecklist;
    const idCheckItem = dragItem.idCheckItem;

      
    const checklistName = dragItem.name + " (R)"; 

    const checkItemData = {name: checkItemName, idChecklist: idChecklistRoute, pos: pos };
    const checklistData = {name: checklistName};

    
    //const postCheck = routeCheck(dragItem); // Why was this added?
    
    // Special cases (Action drop|carry|return)
    if(type === 'moveTitgtoDriver' ){ 


      // Special cases (Action drop|carry|return)
      const action = dragItem.action.toLocaleLowerCase();
      const includesDrop = action.includes('drop') || action.includes('carry') || action.includes('boom');

      // Drop Case
      if (includesDrop && dragItem.checkItems.length === 0) {
        checkItemName = "**" + this.capitalizeWords(dragItem.action) + " @ " + dragItem.location.trim() + "** " + dragItem.url;    
        checkItemData.name = checkItemName;

        postData.push(this.trelloService.buildPostPath('add_checkItem', idChecklistRoute, checkItemData));
      
        // Make and post changes
        routeChanges.push( this.mapRouteChange('add_checkItem', routeDragDrop,type));
      }else{ // ALL OTHER CASES
        if(dragItem.name.toLowerCase().includes('pickup')){dragItem.name.replace(/^pickup*/,'PICKUP')}
        checkItemData.name = '**' + this.capitalizeWords(dragItem.name) + '**' + " " +  dragItem.url;    

        // None drop drag items have to have checklist name ammended and item added. Two Route actions 
        postData.push (this.trelloService.putRequest('update_checklist',null, idChecklistCard, null, checklistData));
        postData.push(this.trelloService.buildPostPath('add_checkItem', idChecklistRoute, checkItemData));

        // Make and post changes
        routeChanges.push(this.mapRouteChange('update_checklist', routeDragDrop ,type));
        routeChanges.push(this.mapRouteChange('add_checkItem', routeDragDrop ,type));
      }
    };

    // Move a drag item type driver to driver
    if(type === 'moveDrivertoDriver'){ 
      checkItemData.name  = dragItem.name.trim();  

      postData.push(this.trelloService.buildPostPath('add_checkItem', idChecklistRoute, checkItemData));
      postData.push(this.trelloService.delRequest('del_checkItem', idChecklistCard, idCheckItem));

      // Make and post changes
      //routeChanges.push(this.mapRouteChange('add_checkItem', passDropData, type));
      //routeChanges.push(this.mapRouteChange('del_checkItem', passDropData, type));
    };

    return postData;
  }

  setupDriverHeaders(driver: Driver): Driver{
    const newDriver = driver
    const driverRouteCard = new TrelloCard;


    const today = new Date;
    const fDate: string = (today.getMonth() + 1 + '/' + today.getDate()+ '/' +  today.getFullYear()).toString() ;
    let strHour = '';
    if(today.getHours()>9){ strHour = today.getHours().toString()}else{strHour = '0' + today.getHours().toString()}

    const driverStartTime = parseInt(driver.driverInfo.startTime.charAt(1));

    let routeName = '';

    // If routing begin after start time or before
    if(driverStartTime && driverStartTime > today.getHours()){
      routeName =  driver.driverInfo.startTime + ' ' + fDate + ' ' + driver.driverInfo.initial;
    }else {
      let hour = ''
      if(today.getHours() > 9){ 
        
        hour = strHour + ':' + today.getMinutes().toString()}
      else { hour = strHour + ':00'}
      routeName = hour + ' ' + fDate + ' ' + driver.driverInfo.initial;
    }

     
    driverRouteCard.name = routeName;

    driverRouteCard.idCardSource = ROUTE_CARD.idCardSource;
    driverRouteCard.keepFromSource = 'all';
    driverRouteCard.idList = driver.trelloInfo.idListDriverRoute
    
    //newDriver.dragItems = [];
    newDriver.routeCard = driverRouteCard;
   

    return  newDriver
  }

  // MARK - POST
  postDriversRouteRequest(drivers: Driver[]){
    const currentDrivers = drivers;
    
    const postRoutes: Observable<any>[] = [];
    const postMaintenances: Observable<any>[] = [];

    // CHECK FOR MOVE CARD
    //const postMoveCards = this.routeService.moveCards(drivers)

    currentDrivers.forEach((driver: Driver) => {  
      // Init Maintenance && Driver Card 
      const driverMaintenanceCard = CARD_DAILY_MAINTENANCE;
      const driverRouteCard = this.setupDriverHeaders(driver);

      // Get listId's
      const idListDriverRoute = driver.trelloInfo.idListDriverRoute;
      const idListDriverCards = driver.trelloInfo.idListDriverCards;

      // Setup Cards
      driverMaintenanceCard.idList = idListDriverCards;

      // Build Posts
      const postRoute = this.trelloService.buildPostPath('add_card', idListDriverRoute, driverRouteCard.routeCard);
      const postMaintenance = this.trelloService.buildPostPath('add_card',idListDriverCards, driverMaintenanceCard);
      
      postRoutes.push(postRoute);
      postMaintenances.push(postMaintenance);

      //urls.push(createUrl)
      //data.push(routeMainCard);
    });

    const posts = [...postRoutes, ...postMaintenances]

    return forkJoin(posts)
  }

  // MARK - BUILDs
  buildEmail(dragItem: DragItem){      
    const defaultVendor = DEFAULT_VENDORS;

    // Long check will be used when an address is added
    let location = dragItem.location.toLowerCase();

    // Check for long address
    if(location.includes(':')){location = location.replace(/^(.*?):.*/, '$1')};

    // Check for ordered status or routed
    if(location.includes('(o)')|| location.includes('(ordered)')||location.includes('(r)')|| location.includes('(routed)')){
      location = location.replace(/\s*\(.*?\)\s*/, '');
      location = location.trim();
    }else {location = location.trim()}

      // Get vendors and return
    const vendorName =  defaultVendor.map(vendors => vendors.name.toLocaleLowerCase());
    const vendorIndex = vendorName.findIndex( vendor => vendor === location);

    if(vendorIndex >= 0){
      const emailVendor = defaultVendor[vendorIndex];

      const items: TrelloCheckItem[] = dragItem.checkItems;
      const mappedItemNames = items.map(item => item.name);
      
      const newEmail = new EmailOrder;  
      newEmail.email_to = emailVendor.emailAddress.join(';');
      newEmail.email_cc = 'orders@roomerator.com';

      console.log(dragItem)
      // Setting the subject
      if(dragItem.action.includes('return')){newEmail.email_subject = "Returns (Roomerator)"}
      else{newEmail.email_subject = "New order (Roomerator)"};

      newEmail.email_body = '';
      const timeCheck = new Date
      const timeCurrent = timeCheck.getHours();
      let gretting = 'Good morning,'

      if(timeCurrent > 11){ gretting = 'Good afternoon,' }
      else if(timeCurrent > 16){ gretting = 'Good evening,'}

      // Set email intro
      let bodyIntro = gretting + '\n\nLooking to place this order for pickup today:\n\n#1\n\n';
      if(dragItem.action.includes('return')){gretting + '\n\nWe have some returns coming in today:\n\n#1\n\n'};

      const bodyDetails = `Driver: ` + dragItem.driver + `\n\nPO: ` + dragItem.po + `\nItems:\n`;
      const bodyCheckItems = mappedItemNames.join('\n');

      const bodyConclusion = '\n___________ END ___________\n\nThank you,\n';

      const currentUser = this.accountService.currentAccountInfo();


      const firstName = this.capitalizeWords(currentUser.user.user_first_name);
      
      const lastName = this.capitalizeWords(currentUser.user.user_last_name);

      const bodyTag = '\n' + firstName + ' ' + lastName;


      newEmail.email_body = bodyIntro + bodyDetails + bodyCheckItems + bodyConclusion + bodyTag;

      return newEmail;
    }
   
    return null;

  }

  sendEmail(emailOrder: EmailOrder){
    // Test email up in here: Change to email order for the real deal
    const testEmail = DEFAULT_EMAIL_ORDER;
    
    const jsonEmail = JSON.stringify(testEmail[0]);

    const requestArray = {
      action_id: 'order_email',
      admin_data: jsonEmail
    }

    // The real deal
    //const jsonEmail = JSON.stringify(emailOrder);

    const requestEmail = this.accountService.sendRequest(requestArray,'json','tool-route.service:sendEmail')

    if(requestEmail){
      requestEmail.subscribe({
        next: (data: any) => {
          console.log(data, "<---- this is a move! email sent", );
         },
        error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
      })
    }
  }

  refreshAllBoards(){
    let idListRequest = '';

    const showBoard = this.currentRouteInfo().view.showBoard;
    if(showBoard === 0){idListRequest = this.idListTitg };
    if(showBoard === 1){idListRequest = this.idListTeam };
    if(showBoard === 2){idListRequest = this.idBoardTesting };

    // QUERY TisG
    const titgRequest = this.trelloService.getRequest('list_card', idListRequest );
    const driverBoardRequest = this.getDriverBoards('route_card');
    const driverCardRequest = this.getDriverBoards('cards')

    

    const joinRequest:Observable<any>[] = [titgRequest, driverBoardRequest, driverCardRequest];

    // Setup up Batch Request
    const forkRequest = forkJoin(joinRequest);

    // Request Board
    if(forkRequest){
      forkRequest.subscribe({
        next: (data: any) => {
          const currentRouteInfo = this.currentRouteInfo();
          const titgCards: TrelloCard[] = data.shift();
          currentRouteInfo.cards = titgCards;

          if(titgCards && titgCards.length > 0){
            this.setAllTrelloInformation(titgCards, "titg", 'checklists')}
          else{ // EMPTY route
            currentRouteInfo.titgCardCatalog = [];
            this.nextRouteInfo(currentRouteInfo, 'refreshAllBoards');
          }
          this.setDriverTrelloInfo(data);            
          this.completLogisticsSetup();
        },
        error: (error: any) => { console.error(error)}
      })
    }

    // QUERY ACTIVE DRIVERS
    // QUERY DRIVER BOARDS
  }

  getDriverBoards(request: string): any{
    const drivers = CURRENT_DRIVERS;
    const urls: string[] = [];

    switch(request){
      case 'cards':      
        drivers.forEach((driver: Driver) => {
          const idBoardDriver = driver.trelloInfo.idListDriverCards;
          
          const url = `/lists/${idBoardDriver}/cards?`
          urls.push(url)
        });
      return this.trelloService.getBatchRequest(urls);
      
      case 'route_card':
        drivers.forEach((driver: Driver) => {
          const driverRouteID = driver.trelloInfo.idListDriverRoute;
          const url = `/lists/${driverRouteID}/cards?`;
          urls.push(url);
        });
      return this.trelloService.getBatchRequest(urls)
    }

    return urls;
  }

  completLogisticsSetup(){
    // TESTING DRIVER SETUP
    //this.routeInfo.drivers = TESTING_DRIVER;
    const newRouteView = this.currentRouteInfo();
    // CURRENT ACTIVE DRIVERS
    newRouteView.view.isLoading = false;
    newRouteView.view.showRoutePage = true;
    newRouteView.view.trelloView.showBoards = true;
    this.nextRouteInfo(newRouteView, "completLogisticsSetup");
  
  }
  
  // MARK - Simple helpers
  capitalizeWords(str: string) {
    return str.replace(/\b\w/g, function (match) {
        return match.toUpperCase();
    });
  }

  formatBoldText(inputString: string) {
    let firstOccurrence = inputString.replace(/\*\*/, '<b>');
    let finalString = firstOccurrence.replace(/\*\*/, '</b>');
    return finalString;
  }

  fillActiveBtnArray(count: number|null): boolean[][][]{
    const currentView = this.currentRouteInfo(); 
    const boardCount = currentView.view.routeBtnContextView.showBtnOptions.length;

    let driverCount = -1;
    if(!count){ 
      const currentDriverCount = currentView.activeDrivers.length;
      const currentTitgCardCount = currentView.titgCardCatalog.length;
      if(currentDriverCount > currentTitgCardCount){driverCount = currentDriverCount}
      else{driverCount = currentTitgCardCount};
    }
    else{driverCount = count}
    const activeDriverBtns = currentView.view.routeBtnContextView.showBtnOptions[1];

    let topCount = 0
    activeDriverBtns.forEach(el => {if(el.length > topCount){topCount = el.length}});
    const btnOptions = new Array(boardCount);

    if(topCount === 0){ topCount = 20};

    for (let i = 0; i < boardCount; i++) {
        btnOptions[i] = new Array(driverCount);
        for (let j = 0; j < driverCount; j++) {
            btnOptions[i][j] = new Array(topCount);
            for (let k = 0; k < topCount; k++) {
                btnOptions[i][j][k] = false;
            }
        }
    }

    DEFAULT_ROUTE_OPTIONS_BUTTON.forEach(el =>{el.disabled = false})
    
    return btnOptions;
  }

  assignRouteChange(data: TrelloCard | DragItem, driverIndex:number, boardIndex: number, itemIndex: number, changeType: string, from: string): RouteChange{
    const newRouteChange = new RouteChange;
    
    newRouteChange.changeType = changeType;

    if(data instanceof TrelloCard){newRouteChange.trelloCard = data};
    if(data instanceof DragItem){newRouteChange.dragItem = data};
    newRouteChange.indexCheckItem = itemIndex; 
    newRouteChange.indexDragItem = boardIndex;
    newRouteChange.indexCardDriver = driverIndex;
    newRouteChange.from = from;   

    return newRouteChange;
  }

  generateRouteBtnContextViewFromDragItem(dragIem: DragItem|null, idCard: string, from: string): RouteBtnContextView{
    
    const dragItem = new DragItem;
    dragItem.idCard = idCard

    const newRouteBtnContextView = new RouteBtnContextView
    newRouteBtnContextView.btnContextMenuInfo = new RouteChange;
    newRouteBtnContextView.btnContextMenuInfo.dragItem = dragItem;

    return newRouteBtnContextView;
  }

  handleError(error: any) {
    console.error('An error occured', error);
    return Promise.reject(error.message || error);
  }
}